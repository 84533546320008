import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notification({ apiResponse, setApiResponse }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (apiResponse.status) {
      handleNotifcation();
    }
  }, [apiResponse]);

  const handleNotifcation = () => {
    setOpen(true);
    // Clear the apiResponse after the notification is displayed
    setTimeout(() => {
      setApiResponse({});
    }, 5000); // Change the delay as needed
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {apiResponse.status &&  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={apiResponse.status}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {apiResponse.message}
        </Alert>
      </Snackbar>}
     
    </div>
  );
}
