import React from 'react'
import PostCard from '../../PostCard'
import XPostView from './XPostView'
import LinkedInPost from './linkedinPostView'

export default function FullpostView({selectedChannels,selectedPostMedia,selectedPostContent,selectedFiles1,imageIndex}) {
  return (
    <div className='justify-start  my-6 mr-10 rounded-2xl '>
    {selectedChannels.length > 0   ?

        <div className=' flex flex-wrap justify-center   rounded-2xl   overflow-y-scroll' >
            {selectedChannels.some(channel => channel.platform === 'X') && (
                <XPostView
                    selectedPostMedia={selectedPostMedia}
                    selectedPostContent={selectedPostContent}
                    selectedFiles1={selectedFiles1}
                    imageIndex={imageIndex}
                />
            )}
            {selectedChannels.some(channel => channel.platform === 'LinkedIn') && (
               <LinkedInPost
               
               userName='Abhishek'
               
               content={selectedPostContent}
               selectedPostMedia={selectedPostMedia}
               selectedFiles1={selectedFiles1}
               imageIndex={imageIndex}
             />
            )}
        </div>
        : <PostCard className='' />}

</div>
  )
}
