import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { plannerUpdate } from '../../../store/slices/plannerUpdateSlice';
import { useNavigate } from "react-router-dom";
import DeployPost from '../deployPost';
import Popup from 'reactjs-popup';
import dayjs from 'dayjs';
export default function EditSchedules({ selectedEvent, setSelectedEvent }) {
    const mediaUrl = selectedEvent.schedule.media
    // const scheduleTime = selectedEvent.start
    const caption = selectedEvent.schedule.caption
    const [open, setOpen] = React.useState(false);
    const [editing,setEditing] = useState(false)
    const [mediaPost,setMediaPost] = useState(true)
    const [scheduleTime,setScheduleTime] = useState(selectedEvent.schedule.dateTime)
    const [screenWidth,setScreenWidth] = useState(window.innerWidth)
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(()=>{
        if(mediaUrl.imageUrlArray.length<0){
            setMediaPost(false)
        }
    },[mediaUrl])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseEvent = () => {
        setSelectedEvent(null)
    }
 const scheduleId = selectedEvent.id;


 const accessToken = localStorage.getItem('accessToken'); 

// Function to delete the schedule
async function deleteSchedule() {
    console.log(accessToken)
    

  try {
    const scheduleResponse = await Axios.delete(
        `https://digisolvertechnologies.in/api/schedules/${scheduleId}`,
      
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    console.log('Schedule deleted successfully:', scheduleResponse.data);
    // Handle success, if needed
  } catch (error) {
    console.error('Error deleting schedule:', error);
    // Handle error, if needed
  }
}
    const handleDelete = async() => {
       await deleteSchedule()
       dispatch(plannerUpdate()) 
       setSelectedEvent(null)
       setOpen(false);

    }
    const handleEdit = ()=>{
        setEditing(true)
// Navigate('/user/deploy')
    }
    const handleCloseEdit = ()=>{
        setEditing(false)
    }
   
    useEffect(()=>{
setScreenWidth(window.innerWidth)
    },[window.innerWidth])
    return (
        <div className={`flex flex-col bg-[#b0a1df] shadow-inner max-h[600px] p-4 overflow-y-scroll rounded-2xl  w-fit`}>

            <div className='flex flex-row-reverse'> <svg className='cursor-pointer' onClick={handleCloseEvent} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="close"><path fill="none" d="M0 0h24v24H0Z"></path><path fill="#525863" d="M15.741 17.61 12 13.87l-3.742 3.74a1.323 1.323 0 0 1-1.873-1.869L10.128 12 6.385 8.258a1.324 1.324 0 0 1 1.873-1.873L12 10.127l3.741-3.742a1.324 1.324 0 0 1 1.873 1.873L13.872 12l3.742 3.741a1.323 1.323 0 0 1-1.873 1.869Z"></path></svg></div>
            <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-center mb-8 gap-4 '>
                <svg className='w-20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="timer"><g><path d="M19.09 19.07h5.48a1 1 0 1 0 0-2H19.09a1 1 0 0 0 0 2zM14.79 25.88h9.26a1 1 0 0 0 0-2H14.79a1 1 0 0 0 0 2zM10.58 32.69H24.3a1 1 0 1 0 0-2H10.58a1 1 0 1 0 0 2zM25.57 38.49a1 1 0 0 0-1-1H5.76a1 1 0 0 0 0 2H24.57A1 1 0 0 0 25.57 38.49zM24.59 45.3a1 1 0 0 0-1-1H11a1 1 0 0 0 0 2H23.59A1 1 0 0 0 24.59 45.3zM24.57 51.11H19.09a1 1 0 0 0 0 2h5.48a1 1 0 1 0 0-2zM32.7 38.52a3.6 3.6 0 0 0 2.13-.7l.1-.09 10.26-9.92a1 1 0 0 0-1.09-1.64l-12.31 5.3-1 .43a.82.82 0 0 0-.2.12 3.6 3.6 0 0 0 2.14 6.5z"></path><path d="M54.54,16.08a1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l3-3a1,1,0,0,0,.3-.7,1,1,0,0,0-.3-.71L55.77,8.47a1,1,0,0,0-1.41,0l-3,3a1,1,0,0,0,0,1.41l1,.95-2.11,2.1A25.88,25.88,0,0,0,36.25,9.42v-1h2.66a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H27.22a1,1,0,0,0-1,1V7.47a1,1,0,0,0,1,1h2.66v.87a23.53,23.53,0,0,0-3.67.68A1,1,0,0,0,26.72,12a22.72,22.72,0,0,1,4.12-.69h0l.2,0,.66,0h0v1.18a1,1,0,0,0,2,0V11.2a23.71,23.71,0,0,1,10,2.68h0l-.6,1a1,1,0,0,0,1.73,1l.6-1a23.72,23.72,0,0,1,7.37,7.34h0l-1.09.63a1,1,0,0,0,1,1.73l1.11-.64A23.5,23.5,0,0,1,56.63,34H55.3a1,1,0,0,0,0,2h1.34A23.82,23.82,0,0,1,54,46.14l-1.19-.69a1,1,0,1,0-1,1.73l1.19.68h0a24,24,0,0,1-7.4,7.41h0l-.7-1.21a1,1,0,0,0-1.73,1l.7,1.21A23.74,23.74,0,0,1,33.67,59h0v-1.4a1,1,0,0,0-2,0V59a23.29,23.29,0,0,1-4.95-.74,1,1,0,0,0-1.22.71,1,1,0,0,0,.71,1.23,25.9,25.9,0,0,0,25.4-42.82l2.1-2.1Z"></path></g></svg>
                <p className='text-2xl'>SCHEDULED</p>
            </div>
            {/* <p>at {scheduleTime.date}</p> */}
            </div>
            <div className='m-4 flex flex-row justify-evenly'>
            <button  onClick={handleEdit} className='cta'>
                    Edit
                </button>
                <button variant="contained" className='border-2 border-red-700 rounded-md px-6 py-3 text-red-500' onClick={handleClickOpen}  >
                    Delete
                </button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, you want to delete schedule ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleDelete} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
               
            </div>
            <div className=' flex flex-col mx-6 p-3 align-top bg-white'>
                <div className='flex flex-row mb-2 gap-1 align-bottom'>
                    <img src='/img/socialmediaicons/instaButton.png' className='rounded-full w-5' />
                    <p>socialsynchub.com</p>
                </div>
                <p>{caption}</p>
              {mediaPost &&  <img src={mediaUrl.imageUrlArray[0]} style={{ maxWidth: '240px' }} />} 
            </div>
            {editing && <><div className={` flex flex-col justify-start pb-4 w-screen fixed scale-90 top-1 left-10 rounded-2xl z-50 ${editing ? 'visible' : 'hidden'} bg-[#9f9d9be3]`}>
            <div className='flex flex-row-reverse'> <svg className='cursor-pointer' onClick={handleCloseEdit} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="close"><path fill="none" d="M0 0h24v24H0Z"></path><path fill="#525863" d="M15.741 17.61 12 13.87l-3.742 3.74a1.323 1.323 0 0 1-1.873-1.869L10.128 12 6.385 8.258a1.324 1.324 0 0 1 1.873-1.873L12 10.127l3.741-3.742a1.324 1.324 0 0 1 1.873 1.873L13.872 12l3.742 3.741a1.323 1.323 0 0 1-1.873 1.869Z"></path></svg></div>
                <DeployPost schedule={selectedEvent}/></div></>}
            

        </div>
    )
}
