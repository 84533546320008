import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Features from '../components/features';

export default function Home() {
  const animatedRef = useRef(null);
  const imgRef = useRef(null);
  const [heroImgHover, setHeroImgHover] = useState(false)
  const mouseEnter = ()=>{
setHeroImgHover(true)
  }
  const mouseExit = ()=>{
setHeroImgHover(false)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animated');
            observer.unobserve(entry.target); // Stop observing after animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }
    
    if (imgRef.current) {
      observer.observe(imgRef.current); // Observe the image as well
    }

    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div className='bgHero'>
      <Header />
      <div id='/' className='flex flex-col  px-4'>
        <div className='flex flex-col my-4 gap-20 lg:gap-40 items-center '>
          <div className='flex flex-col w-full gap-14 pt-20 md:pt-40'>
            <div className='flex flex-col items-center justify-center text-gray-800 z-30'>
              <p className='text-5xl font-bold text-gray-800 text-center mb-4 alkatra-Brand ' >
                Manage <span className='textPrimary'>Social Media</span> From <span className='textPrimary'>One Place</span>
              </p>
              <p className='text-md font-semibold max-w-md w-fit text-center' >
                Schedule and Post Across Multiple Social Media Platforms!
              </p>
              <Link to={'/registerUser'} className='cta mt-4'>
                Get Started Free
              </Link>
            </div>
          </div>
       
          <div className='flex flex-col justify-center items-center mt-8 '>
            <div  className='relative flex justify-center'>
            <div className={`absolute flex gap-4 justify-center top-[-300px] left-0 right-0 `}>
            <img src='/img/socialmediaIcons/fbButton.png' className={` w-12  transition-all duration-700  ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            <img src='/img/socialmediaIcons/instaButton.png' className={`w-12  transition-all duration-700 delay-200 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-x-[600px]  translate-y-[600px] ' }`} />
            <img src='/img/socialmediaIcons/linkedin.png' className={`w-12  transition-all duration-700  delay-500 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            <img src='/img/socialmediaIcons/Xbutton.png' className={`w-12  transition-all duration-700 ${heroImgHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[600px]'}`} />
            </div> 
            <img
              src='/img/accounts.png '
              className='bg-slate-100 rounded-md shadow-lg z-30 w-full px-2 lg:w-[60%] hero-img '
              ref={imgRef}
              onMouseEnter={mouseEnter}
              onMouseOut={mouseExit}
            />
            
         
            </div>
          
               
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-center items-center gap-12 bgSec2 py-32 px-4'>
        <h3 className='text-2xl lg:text-4xl font-bold max-w-lg text-center lg:text-start'>
          Efficiently <span className='textSecondary'>Schedule</span> your posts across all social media platforms—
          <span className='textSecondary'>trust</span> us to handle it for you.
        </h3>
        <img src='/img/scheduleMockup.png' className='bg-slate-100 rounded-md shadow-lg z-30 w-[100%] lg:w-[50%]' />
      </div>

      <div className='bgFeature p-4'  id='features'>
        <Features />
      </div>

      <div className='flex flex-col gap-4 items-center justify-center py-20 w-full bgPrimary px-4'>
        <h2 className='text-4xl font-bold text-center'>Start scheduling your posts for free</h2>
        <Link to={'/registerUser'} className='cta'>
          Start Scheduling
        </Link>
      </div>

      <Footer />
    </div>
  );
}
