import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};



export default function ChannelSelect({ initialSelectedChannel, onChannelSelect,screenWidth }) {
  const [selectedChannel, setSelectedChannel] = React.useState([]);
  const [updatedSocialMedia, setUpdatedSocialMedia] = React.useState([]);
  const socialMediaString = sessionStorage.getItem('socialMedia')
let socialMedia = []
if(socialMediaString){

   socialMedia = JSON.parse(socialMediaString)
//    let updatedSocialMediaChaneels = socialMedia.map((item) => {
//     if (item.Platform === "Facebook") {
//         return {
//             ...item,
//             icon: "/img/socialmediaIcons/fbButton.png"
//         };
//     }
//     return item;
// });
// setUpdatedSocialMedia(updatedSocialMediaChaneels)
}
  React.useEffect(()=>{
    if(initialSelectedChannel.length > 0){
      setSelectedChannel(initialSelectedChannel)
      console.log('selectedChannelSelect',selectedChannel)
    }
   
  },[ window.location.href])

  const handleChange = (event) => {
    const {
      target: { value: selectedValues },
    } = event;
  console.log('selectedValue',selectedValues)
    // Extract channel IDs if necessary
    // const selectedChannelIds = selectedValues.map(value => typeof value === 'object' ? value._id : value);
  
    // // Find the selected channels using their IDs
    // const selectedChannels = socialMedia.filter(channel => selectedChannelIds.includes(channel._id));
  
    // Update selected channels
    setSelectedChannel(selectedValues);
  
    // Pass the updated selected channels to the parent component
    onChannelSelect(selectedValues);
  };
const setChannelIcon =(channel)=>{
  if(channel.platform == 'Facebook'){
    return "/img/socialmediaIcons/fbButton.png"
  }

  else if(channel.platform == 'Instagram'){
    return "/img/socialmediaIcons/instaButton.png"
  }
  else if(channel.platform == 'LinkedIn'){
    return "/img/socialmediaIcons/linkedin.png"
  }
  else if(channel.platform == 'X'){
    return "/img/socialmediaIcons/Xbutton.png"
  }
}

  return (
    <div>
      <FormControl sx={{ m: 1, width: 400}}>
        <InputLabel id="demo-multiple-checkbox-label">Channels</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          label = 'Channels'
          id="demo-multiple-checkbox"
          multiple
          value={selectedChannel}
          onChange={handleChange}
          input={<OutlinedInput label="Channels" />}
          renderValue={(selected) => selected.map((channelId) => {
            const channel = socialMedia.find((item) => item._id === channelId);
            return channel ? channel.userName : '';
          }).join(', ')}
          MenuProps={MenuProps}
        >
          {socialMedia.map((channel) => (
            <MenuItem key={channel._id} value={channel._id}>
              <Checkbox checked={selectedChannel.some((selected) => selected === channel._id)}  />
              <ListItemText primary={channel.userName} />
              <img src={setChannelIcon(channel)} width="32px" alt="Channel Icon" />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
