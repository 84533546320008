import React, { useState, useEffect, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const Dropdown = ({ label, options, selectedOptions, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* <label className="block text-gray-700 mb-2">{label}</label> */}
      <div className="flex mt-0">
        <button
          type="button"
          onClick={toggleDropdown}
          className=" text-black mt-0 rounded w-full"
        >
            <div className='flex flex-row justify-between '>
                <p>{label} Example</p>   {isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>} 
            </div>
      
        </button>
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-full bg-white border rounded shadow-md z-10">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
