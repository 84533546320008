import React, { useState } from 'react';

const features = [
  {
    title: "Multi-Channel Posting",
    description: "Publish content across various social media platforms from one dashboard.",
    videoSrc: '/img/accounts.png',
     
  },
  {
    title: "Scheduling",
    description: "Plan and schedule posts in advance with our intuitive calendar.",
    videoSrc: '/img/scheduleMockup.png'
  },
  {
    title: "Content Calendar",
    description: "Monitor campaigns using a content calendar.",
    videoSrc: '/img/calender.png'
  }
];

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(0);

  return (
    <div className="flex flex-col lg:flex-row gap-20  py-20 rounded-md justify-between items-center px-8 border-1 border-gray-300 my-20">
      <div className="flex flex-col gap-4 ">
        {features.map((feature, index) => (
          <div
            className={`flex flex-col z-30 border-[1px] border-violet-500 ${selectedFeature === index ? 'bgPrimary border-2' : '' } featureEach rounded-md p-4 shadow-md cursor-pointer`}
            key={index}
            onClick={() => setSelectedFeature(index)}
          >
            <p className="text-xs textSecondary">{feature.title}</p>
            <h3 className="text-lg font-bold">{feature.title}</h3>
            <p className={`max-w-md ${selectedFeature === index ? 'flex' : 'hidden' } lg:flex`}>{feature.description}</p>
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center w-[100%] lg:w-[60%]">
        {features[selectedFeature].videoSrc ? (
          <img src={features[selectedFeature].videoSrc} alt={features[selectedFeature].title} className="w-full rounded-md shadow-lg z-30" />
        ) : (
          <div className="text-gray-500">No video available for this feature</div>
        )}
      </div>
    </div>
  );
};

export default Features;
