import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className='flex justify-center items-centerS'>
    <p>© 2024 SocialSyncHub.com</p>
    </div>
  );
}
