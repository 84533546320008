import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-8 bg-white shadow-md mt-10" id='privacyPolicy'>
            <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy</h1>
            <p className="text-gray-600"><strong>Effective Date:</strong> June 29 2024</p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
            <p className="text-gray-600">
                Welcome to SocialSyncHub ("we", "our", "us"). We are committed to protecting and respecting your privacy. 
                This Privacy Policy explains how we collect, use, and disclose information about you when you use our 
                social media management application (the "App").
            </p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">2. Information We Collect</h2>
            <p className="text-gray-600">We collect and process the following information:</p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
                <li><strong>Personal Information:</strong> When you sign up for and use the App, we collect personal information such as your name, email address, and any other information you provide.</li>
                <li><strong>Social Media Access Tokens:</strong> To enable posting and scheduling on your behalf, we collect and store the access tokens for the social media accounts you connect to the App.</li>
                <li><strong>Usage Data:</strong> We collect information about your use of the App, such as your activity, the features you use, and the time you spend on the App.</li>
                <li><strong>Device Information:</strong> We may collect information about the device you use to access the App, including the device type, operating system, and browser type.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">3. How We Use Your Information</h2>
            <p className="text-gray-600">We use the information we collect for the following purposes:</p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
                <li>To provide, operate, and maintain the App.</li>
                <li>To enable you to connect to and manage your social media accounts.</li>
                <li>To schedule and post content on your behalf to your connected social media accounts.</li>
                <li>To communicate with you, including sending updates, notifications, and support messages.</li>
                <li>To analyze usage and improve the App.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">4. How We Share Your Information</h2>
            <p className="text-gray-600">We do not sell or rent your personal information. We may share your information in the following circumstances:</p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
                <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as hosting and maintaining the App.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to a legal request.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">5. Security</h2>
            <p className="text-gray-600">We implement appropriate technical and organizational measures to protect the security of your personal information. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">6. Retention</h2>
            <p className="text-gray-600">We retain your personal information for as long as necessary to provide the services you have requested, or as required by law. Access tokens are stored only for as long as they are needed to provide the scheduling and posting services and will be deleted if you disconnect your social media accounts from the App.</p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">7. Your Rights</h2>
            <p className="text-gray-600">You have the following rights regarding your personal information:</p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
                <li><strong>Access:</strong> You have the right to request access to your personal information.</li>
                <li><strong>Correction:</strong> You have the right to request that we correct any inaccurate or incomplete personal information.</li>
                <li><strong>Deletion:</strong> You have the right to request the deletion of your personal information. You can disconnect your social media accounts at any time, and we will delete the associated access tokens.</li>
                <li><strong>Objection:</strong> You have the right to object to the processing of your personal information in certain circumstances.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">8. Changes to This Privacy Policy</h2>
            <p className="text-gray-600">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and in the App. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">9. Contact Us</h2>
            <p className="text-gray-600">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [Your Contact Information].</p>

            <p className="text-gray-600">By using our App, you acknowledge that you have read and understood this Privacy Policy.</p>
        </div>
    );
};

export default PrivacyPolicy;
