import React from 'react'
// import Loader from "react-js-loader";
import { RotatingLines } from 'react-loader-spinner'



export default function Loading() {
  return (
    <RotatingLines
    visible={true}
    height="15"
    width="40"
    color="white"
    strokeWidth="5"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
    />
  )
}
