import React from 'react'
import Header from '../components/header/header'
import { Helmet } from 'react-helmet-async';

export default function About() {
  return (
    <>
       <Helmet>
        <title>About - SocialSyncHub</title>
        <meta
          name="description"
          content="Learn more about SocialSyncHub and how it helps you manage multiple social media accounts efficiently."
        />
        <meta
          name="keywords"
          content="about social media tool, SocialSyncHub, social media management"
        />
        <link rel="canonical" href="https://socialsynchub.com/about" />
      </Helmet>
    <Header/>
    <div id='about '>
      <section class="my-20 mx-6 sm:mx-20">
  <div class="max-w-4xl mx-auto">
    <h2 class="text-4xl font-bold text-[#201515] mb-6">About Us</h2>
    <p class="text-lg text-gray-600 mb-6">
      Welcome to SocialSyncHub, where social media management meets simplicity. Our mission is to empower individuals and businesses to effortlessly navigate the world of social media, connecting with their audience in meaningful ways.
    </p>
    <p class="text-lg text-gray-600 mb-6">
      We understand the challenges of maintaining a vibrant online presence, and that's why we're committed to providing a user-friendly platform that streamlines your social media experience. But we're not stopping here!
    </p>
    <p class="text-lg text-gray-600 mb-6">
      Future plans include seamless integration with various social media platforms, allowing you to link your accounts and manage them all from one central hub. Imagine creating and scheduling posts directly from our website, saving you time and simplifying your social media strategy.
    </p>
    <p class="text-lg text-gray-600 mb-6">
      Stay tuned for exciting updates as we work towards making social media management even more convenient for you. Your success on social media is our priority, and we're here to make it happen.
    </p>
  </div>
</section>

    </div>
    </>
  )
}
