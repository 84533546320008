// // BlogPage.js
// import React from 'react';
// import { useParams } from 'react-router-dom';

// const BlogFull = ({ blogPosts }) => {
//   const { id } = useParams();
//   const selectedBlog = blogPosts.find((post) => post.id.toString() === id);

//   if (!selectedBlog) {
//     // Handle the case where the blog post with the specified ID is not found
//     return <p>Blog not found</p>;
//   }

//   return (
//     <div className="mt-20 mx-4 sm:mx-20">
//       <div className="max-w-4xl mx-auto">
//         <h2 className="text-4xl font-bold text-[#201515] mb-6">{selectedBlog.title}</h2>
//         <p className="text-gray-600 text-sm mb-2">{selectedBlog.date}</p>
//         <p className="text-gray-700">{selectedBlog.content}</p>
//       </div>
//     </div>
//   );
// };

// export default BlogFull;



// BlogFull.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Blogs from './blogs';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';


const BlogFull = ({blogPosts} ) => {
  const { id } = useParams();
  const selectedBlog = blogPosts.find((post) => post.id.toString() === id);
useEffect(()=>{
    console.log("blogFull")
})

  if (!selectedBlog) {
    // Handle the case where the blog post with the specified ID is not found
    return <p>Blog not found</p>;
  }

  return (
    <>
    <Header/>
    <div className="mt-20 mx-4 sm:mx-20">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-[#201515] mb-6">{selectedBlog.title}</h2>
        <p className="text-gray-600 text-sm mb-2">{selectedBlog.date}</p>
        <p className="text-gray-700">{selectedBlog.content}</p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default BlogFull;
