import React, { useState, useEffect } from 'react';

const Pricing = () => {
  const [billPlan, setBillPlan] = useState('monthly');

  const toggleBillPlan = () => {
    setBillPlan((prevPlan) => (prevPlan === 'monthly' ? 'annually' : 'monthly'));
  };

  const plans = [
    {
      name: 'Basic',
      description: 'Better for creators and new businesses that want more customers.',
      price: {
        monthly: 14,
        annually: 14 * 12 - 100,
      },
      features: ['2 channels', 'Scheduling', 'Planner', 'GPT 3.5'],
      planId: 'plan_OQ1AvEkwFm9Wx1', // Replace with your actual plan ID from Razorpay
    },
    {
      name: 'Custom',
      description: 'Advanced features for pros who need more customization.',
      price: {
        monthly: 100,
        annually: 100 * 12 - 100,
      },
      features: ['Everything in Basic', 'Unlimited channels', 'GPT 4', '24*7 Chat support'],
      planId: 'plan_OQ19wFQGKSa5K7', // Replace with your actual plan ID from Razorpay
    },
  ];

  const handlePlan = async (plan) => {
    const planId = plan.planId;
    const amount = billPlan === 'monthly' ? plan.price.monthly * 100 : plan.price.annually * 100;

    try {
      const response = await fetch('http://localhost:5000/create-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planId, billPlan }),
      });

      const data = await response.json();

      if (data.id) {
        openRazorpay(data.id);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const openRazorpay =  (subscriptionId) => {
    const options = {
      key: 'rzp_test_fhVrZ5hTrUIRyB', // Enter the Key ID generated from the Dashboard
      subscription_id: subscriptionId,
      name: 'SocialSyncHub Premium',
      description: 'Product Description',
      handler: async function (response) {
        // Handle successful payment here
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_subscription_id);
        console.log(response.razorpay_signature);
        // Handle any post-payment logic here
        try {
          const res = await fetch('http://localhost:5000/verify-payment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, // Include access token here
            },
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_signature: response.razorpay_signature,
            }),
          });
  
          const result = await res.json();
  
          if (result.success) {
            // Handle post-payment success logic
            console.log('Payment successful!');
            // For example, navigate to a success page or show a success message
          } else {
            console.error('Payment verification failed.');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '1234567890',
      },
      theme: {
        color: '#F37254',
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id='pricing'>
      <div className='bg-gray-200'>
        <section className='sm:px-48 px-4'>
          <div className="flex items-center justify-center mt-10 space-x-4">
            <span className="text-base font-medium">Bill Monthly</span>
            <button
              className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={toggleBillPlan}
            >
              <div className="w-16 h-8 transition bg-indigo-500 rounded-full shadow-md outline-none"></div>
              <div
                className={`absolute inline-flex items-center justify-center w-6 h-6 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${
                  billPlan === 'monthly' ? 'translate-x-0' : 'translate-x-8'
                }`}
              ></div>
            </button>
            <span className="text-base font-medium">Bill Annually</span>
          </div>

          <div className="flex flex-col items-center justify-center mt-16 space-y-8 lg:flex-row lg:items-stretch lg:space-x-8 lg:space-y-0">
            {plans.map((plan, index) => (
              <section key={index} className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-gray-100 rounded-md shadow-md">
                <div className="flex-shrink-0">
                  <span className={`text-4xl font-medium tracking-tight ${plan.name === 'Basic' ? 'text-orange-500' : ''}`}>
                    ${billPlan === 'monthly' ? plan.price.monthly : plan.price.annually}
                  </span>
                  <span className="text-gray-400">{billPlan === 'monthly' ? '/month' : '/year'}</span>
                </div>

                <div className="flex-shrink-0 pb-6 space-y-2 border-b">
                  <h2 className="text-2xl font-normal">{plan.name}</h2>
                  <p className="text-sm text-gray-400">{plan.description}</p>
                </div>

                <ul className="flex-1 space-y-4">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-start">
                      <svg
                        className="w-6 h-6 text-gray-300 font-bold"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="bg-gray-100"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="ml-3 text-base font-medium">{feature}</span>
                    </li>
                  ))}
                </ul>

                <div className="flex-shrink-0 pt-4">
                  <button
                    className={`inline-flex items-center justify-center w-full max-w-xs px-4 py-2 transition-colors border rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      plan.name === 'Basic' ? 'bg-gray-500 text-white hover:bg-gray-700' : 'hover:bg-gray-500 hover:text-white'
                    }`}
                    onClick={() => handlePlan(plan)}
                  >
                    Get {plan.name}
                  </button>
                </div>
              </section>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
