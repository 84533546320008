import React, { useState } from 'react';
import LoginButtonExample from '../../components/fbLogin';
import Channels from './Channels';
import Dashboard from './dashboard';

export default function SocialAccount() {
    const [accountUpdate,setAccountUpdate] = useState(true)
    const [burgerMenuClicked, setBurgerMenuClicked] = useState(false);

    const handleBurgerMenu = () => {
        setBurgerMenuClicked(prevState => !prevState);
    }
    return (
        <>

        <div id='socialAccounts' className='flex flex-col bgAppSecondary min-h-screen'>
            {/* <HeaderConsole /> */}
            <div className='flex flex-row static'>
          
    
            <div className={`${!burgerMenuClicked && 'hidden'} md:block  relative top-0 left-0 z-10`}  >
      <Dashboard />
      </div>
      {/* <NavMobile navs={navs} navAccount={navAccount} activeTab={activeTab}/> */}
            <div className='flex flex-col   '>
                <div className='flex flex-row justify-between items-center px-10 my-4 '>
                <p className=' text-violet-500 font-bold text-xl '>Connect Accounts</p>
                {/* <div className='ml-80 mt-4'><PremiumButton/> </div> */}
                <div className="lg:hidden">
                <button id="burger-menu" className="text-black focus:outline-none" onClick={handleBurgerMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-max-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
                </div>
            
            <div className='flex flex-col justify-center relative top-0  px-4 lg:px-12 bg-white  w-full  '>

             
                <LoginButtonExample setAccountUpdate={setAccountUpdate} />
               
                <Channels accountUpdate={accountUpdate}/>
            </div>
            </div>
            </div>
        </div>
        </>
    );
}
