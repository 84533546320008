import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux';
import EditSchedules from "./editSchedules";
import HeaderConsole from "../../../components/header/headerConsole";
import Dashboard from "../dashboard";
import DeployPost from "../deployPost";
import BlurBarrier from "../../../components/blurBarrier";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const Planner = () => {
    const [events, setEvents] = useState([
        {
            start: moment().toDate(),
            end: moment().add(1, 'minute').toDate(),
            title: "",
            imageUrl: []
        },
    ]);
    const [selectedEvent, setSelectedEvent] = useState()
    const [selectSlot, setSelectSlot] = useState(false)
    const [slotDateTime, setSlotDateTime] = useState(null)
    const [burgerMenuClicked, setBurgerMenuClicked] = useState(false);

    const handleBurgerMenu = () => {
        setBurgerMenuClicked(prevState => !prevState);
    }
    const isPlannerUpdated = useSelector((state) => state.plannerUpdate.isplannerUpdated);
    useEffect(() => {
        let accessToken = localStorage.getItem('accessToken');
        async function fetchSchedules() {


            try {
                const scheduleResponse = await axios.get(
                    'https://digisolvertechnologies.in/api/schedules',
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log('Schedule fetched successfully:', scheduleResponse.data);
                const schedules = scheduleResponse.data;
                const newEvents = [];

                schedules.forEach((schedule) => {

                    const timestamp = schedule.dateTime;
                    const date = moment(timestamp).toDate();

                    const platforms = schedule.scheduleData.socialMedia.reduce((acc, platform) => {
                        if (platform.platform === 'X') {
                            acc.push('/img/socialmediaIcons/Xbutton.png');
                        }  if (platform.platform === 'Instagram') {
                            acc.push('/img/socialmediaIcons/instaButton.png');
                        }
                        if (platform.platform === 'Facebook') {
                            acc.push('/img/socialmediaIcons/fbButton.png');
                        }
                        if (platform.platform === 'LinkedIn') {
                            acc.push('/img/socialmediaIcons/linkedin.png');
                        }
                        return acc;
                    }, []);

                    newEvents.push({
                        id: schedule._id,
                        start: date,
                        end: moment(date).add(1, 'minute').toDate(), // Set a default end time (e.g., 1 hour after start time)
                        caption: schedule.scheduleData.caption,
                        postUrl: schedule.scheduleData.cover ? schedule.scheduleData.cover : schedule.scheduleData.caption,
                        imageUrl: platforms,
                        schedule: schedule.scheduleData
                    });
                });
                // console.log('new',newEvents)
                setEvents(newEvents);

            } catch (error) {
                console.log('error getting post', error);
            }
        }
        fetchSchedules()
    }, [isPlannerUpdated]);

    const onEventResize = (data) => {
        const { start, end } = data;

        setEvents((prevEvents) => {
            const updatedEvents = [...prevEvents];
            updatedEvents[0].start = start;
            updatedEvents[0].end = end;
            return updatedEvents;
        });
    };

    const onEventDrop = (data) => {
        const { start, end, event: droppedEvent } = data;

        // Find the index of the dropped event in the events array
        const eventIndex = events.findIndex(event => event.id === droppedEvent.id);

        if (eventIndex !== -1) {
            // Create a copy of the events array
            const updatedEvents = [...events];

            // Update the start and end times of the dropped event based on the dropped position
            updatedEvents[eventIndex] = {
                ...updatedEvents[eventIndex],
                start,
                end
            };

            // Set the updated events array
            setEvents(updatedEvents);
        }
    };

    const EventComponent = ({ event }) => (
        <div className="">
            <div className="flex flex-row justify-between ">
                {<img src={`${event.postUrl}`} className='text-xs' alt={event.caption} style={{ maxWidth: '20px' }} />}
                <svg className="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="timer"><g><path d="M19.09 19.07h5.48a1 1 0 1 0 0-2H19.09a1 1 0 0 0 0 2zM14.79 25.88h9.26a1 1 0 0 0 0-2H14.79a1 1 0 0 0 0 2zM10.58 32.69H24.3a1 1 0 1 0 0-2H10.58a1 1 0 1 0 0 2zM25.57 38.49a1 1 0 0 0-1-1H5.76a1 1 0 0 0 0 2H24.57A1 1 0 0 0 25.57 38.49zM24.59 45.3a1 1 0 0 0-1-1H11a1 1 0 0 0 0 2H23.59A1 1 0 0 0 24.59 45.3zM24.57 51.11H19.09a1 1 0 0 0 0 2h5.48a1 1 0 1 0 0-2zM32.7 38.52a3.6 3.6 0 0 0 2.13-.7l.1-.09 10.26-9.92a1 1 0 0 0-1.09-1.64l-12.31 5.3-1 .43a.82.82 0 0 0-.2.12 3.6 3.6 0 0 0 2.14 6.5z"></path><path d="M54.54,16.08a1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l3-3a1,1,0,0,0,.3-.7,1,1,0,0,0-.3-.71L55.77,8.47a1,1,0,0,0-1.41,0l-3,3a1,1,0,0,0,0,1.41l1,.95-2.11,2.1A25.88,25.88,0,0,0,36.25,9.42v-1h2.66a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H27.22a1,1,0,0,0-1,1V7.47a1,1,0,0,0,1,1h2.66v.87a23.53,23.53,0,0,0-3.67.68A1,1,0,0,0,26.72,12a22.72,22.72,0,0,1,4.12-.69h0l.2,0,.66,0h0v1.18a1,1,0,0,0,2,0V11.2a23.71,23.71,0,0,1,10,2.68h0l-.6,1a1,1,0,0,0,1.73,1l.6-1a23.72,23.72,0,0,1,7.37,7.34h0l-1.09.63a1,1,0,0,0,1,1.73l1.11-.64A23.5,23.5,0,0,1,56.63,34H55.3a1,1,0,0,0,0,2h1.34A23.82,23.82,0,0,1,54,46.14l-1.19-.69a1,1,0,1,0-1,1.73l1.19.68h0a24,24,0,0,1-7.4,7.41h0l-.7-1.21a1,1,0,0,0-1.73,1l.7,1.21A23.74,23.74,0,0,1,33.67,59h0v-1.4a1,1,0,0,0-2,0V59a23.29,23.29,0,0,1-4.95-.74,1,1,0,0,0-1.22.71,1,1,0,0,0,.71,1.23,25.9,25.9,0,0,0,25.4-42.82l2.1-2.1Z"></path></g></svg>
                <div className="flex flex-row">
                    {event.imageUrl.map((url, index) => (
                        <img key={index} src={url} alt={event.title} style={{ maxWidth: '20px' }} />
                    ))}
                </div>
            </div>
            {/* <div>{event.title}</div> */}
        </div>
    );
    const handleSelectEvent = (event) => {
        console.log('clicked', event)
        setSelectedEvent(event)
    }
    const handleCloseSlot = () => {
        setSelectSlot(false)
    }
    return (
        <div id="planner" className="bgAppSecondary  ">

            <div className="flex flex-row  static" >
                <div className={`${!burgerMenuClicked && 'hidden'} md:block  relative top-0 left-0 z-10`}  >
                    <Dashboard />
                </div>
                <div className="flex flex-col static w-screen">
                    <div className="flex flex-row justify-between px-10 py-4">
                        <p className='text-violet-500 font-bold text-xl '>Content calender</p>
                        <div className="lg:hidden">
                            <button id="burger-menu" className="text-black focus:outline-none" onClick={handleBurgerMenu}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-max-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="shadow-inner justify-start static p-4">
                        <BlurBarrier />
                        <DnDCalendar className=" overflow-y-scroll bg-white p-4 overflow-x-scroll "
                            defaultDate={moment().toDate()}
                            defaultView="month"
                            events={events && events}
                            localizer={localizer}
                            onEventDrop={onEventDrop}
                            onEventResize={onEventResize}

                            // timeslots={2}
                            step={60}
                            selectable={true}
                            onSelectEvent={(event) => handleSelectEvent(event)}
                            onSelectSlot={(slotInfo) => {
                                console.log(slotInfo)

                                setSlotDateTime(slotInfo.start)
                                setSelectSlot(true)
                            }}
                            resizable
                            components={{ event: EventComponent }}
                            style={{ height: "100vh", width: '100%' }}
                        />
                        {selectSlot && <><div className={` flex flex-col p-8 relative top-[-800px] lg:right-32 rounded-2xl z-40  bg-white border-2 border-violet-200 shadow-lg max-h-[600px] p`}>
                            <div className='flex flex-row-reverse'> <svg onClick={handleCloseSlot} className='cursor-pointer mr-4 hover:scale-110' xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="close"><path fill="none" d="M0 0h24v24H0Z"></path><path fill="#525863" d="M15.741 17.61 12 13.87l-3.742 3.74a1.323 1.323 0 0 1-1.873-1.869L10.128 12 6.385 8.258a1.324 1.324 0 0 1 1.873-1.873L12 10.127l3.741-3.742a1.324 1.324 0 0 1 1.873 1.873L13.872 12l3.742 3.741a1.323 1.323 0 0 1-1.873 1.869Z"></path></svg></div>
                           <div className="overflow-auto p-4"><DeployPost dateTime={slotDateTime} /></div> </div></>}
                        {selectedEvent && <div className={`${selectedEvent ? 'block' : 'hidden'} relative top-[-800px]  z-30`}> <EditSchedules selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} /></div>}
                    </div>

                </div>


            </div>

        </div>
    );
};

export default Planner;
