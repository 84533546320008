import { createSlice } from "@reduxjs/toolkit";

const plannerUpdateSlice = createSlice({
  name: "plannerUpdate",
  initialState: {
    isplannerUpdated: false,
    
  },
  reducers: {
    plannerUpdate: (state) => {
      state.isplannerUpdated = !state.isplannerUpdated;
      
      console.log("logged in",state.isplannerUpdated)
    }
   
  },
});

export const { plannerUpdate } = plannerUpdateSlice.actions;

export default plannerUpdateSlice.reducer;
