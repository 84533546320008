import React, { useState } from 'react';
import PremiumButton from './button/premiumButton';

const BlurBarrier = ({ children }) => {
  const [isBlurred, setIsBlurred] = useState(true);
  const [premiumUser,setPremiumUser] = useState(true)
  const handleButtonClick = () => {
    setIsBlurred(false);
  };

  return (
    <div className=''>
    {!premiumUser && <div className=" absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-95 z-50">
    <PremiumButton/>
  </div>}
  </div>
  );
};

export default BlurBarrier;