import './App.css';
import Header from './components/header/header';
import Home from './pages/home';
import ContenCreationPage from './pages/contenCreationPage';
import { BrowserRouter, Route, Routes,Navigate} from 'react-router-dom';
import Footer from './components/footer/footer';
import About from './pages/about';
import Blogs from './pages/blogs/blogs';
import Login from './pages/login';
import RegisterUser from './pages/registerUser';
import BlogFull from './pages/blogs/blogFull';
import blogPosts from './pages/blogs/allBlogs';
import PrivateRoute from './components/privateRoute';
import {useDispatch} from "react-redux"
import { login } from './store/slices/authSlice';
import Console from './pages/console/console';
import SocialAccount from './pages/console/socialAccounts';
import Dashboard from './pages/console/dashboard';
import DeployPost from './pages/console/deployPost';
import Planner from './pages/console/planner/planner';
import DeployTab from './pages/console/deployTab';
import Pricing from './components/pricing';
import Payment from './components/payments/payment';
import PrivacyPolicy from './components/privacyPolicy';
import TermsOfService from './components/termsOfService';
import { HelmetProvider } from 'react-helmet-async';



function App() {
  const dispatch = useDispatch()
  const accessToken = localStorage.getItem('accessToken');
  const userName = localStorage.getItem('userName');
  const redirectOn = localStorage.getItem('xInitiated');
 
   
 
  
  if(accessToken && userName)  dispatch(login(userName))
    
  
  if(accessToken && userName && redirectOn === 'true') dispatch(login(userName))
  return (
<HelmetProvider>
    <div className='max-w-full overflow-hidden'>
<BrowserRouter>

<Routes>

  <Route exact path='/' element = {<Home/>}>
  
  </Route>
  <Route path='/pricing' element = {<Pricing/>}></Route>
  <Route path='/payment' element = {<Payment/>}></Route>
  <Route path='/privacyPolicy' element = {<PrivacyPolicy/>}></Route>
  <Route path='/TermsOfService' element = {<TermsOfService/>}></Route>
<Route path='/about' element = {<About/>}></Route>
<Route path='blogs' element={<Blogs blogPosts={blogPosts}/>}></Route>
<Route path='/blogFull/:id' element={<BlogFull blogPosts={blogPosts}/>}></Route>
<Route path='/signIn' element={<Login/>}></Route>
<Route path='/registerUser' element={<RegisterUser/>}></Route>
<Route path='/user' element = {<PrivateRoute/>}>


<Route path='dashboard' element = {<Dashboard/>}>
</Route>
<Route path='socialAccounts' element={<SocialAccount/>}></Route>
<Route path='deployTab' element={<DeployTab/>}></Route>
<Route path='planner' element={<Planner/>}></Route>
</Route>
</Routes>
  
 
</BrowserRouter>
   
    </div>
    </HelmetProvider>
  );
}

export default App;
