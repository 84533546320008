import React from 'react'
import DeployPost from './deployPost'
import Dashboard from './dashboard'
import { useState } from 'react';

export default function DeployTab() {
  const [burgerMenuClicked, setBurgerMenuClicked] = useState(false);

    const handleBurgerMenu = () => {
        setBurgerMenuClicked(prevState => !prevState);
    }
  return (
    <>
<div id='deployTab' className=' h-full '>
    {/* <HeaderConsole /> */}
    <div className='flex flex-row  bgAppSecondary'>
      <div className={`${!burgerMenuClicked && 'hidden'} md:block  relative top-0 left-0 z-10`}>
      <Dashboard />
      </div>
     
        
        <div className='flex flex-col  relative  '>
          <div className='flex flex-row justify-between  px-10 py-4'>
          <p className='  text-violet-500 font-bold text-xl  '>Publish Post</p>
          <div className="lg:hidden">
                <button id="burger-menu" className="text-black focus:outline-none" onClick={handleBurgerMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-max-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
          </div>
        
       <DeployPost />
        </div>
       
    </div>
    </div>
</>
  )
}
