import {configureStore} from "@reduxjs/toolkit"
import authSlice from "./slices/authSlice"
import plannerUpdateSlice from "./slices/plannerUpdateSlice";

const store = configureStore({
reducer:{
    auth: authSlice,
    plannerUpdate:plannerUpdateSlice
},
})

export default store;