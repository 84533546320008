import React from 'react'
import { useSelector } from 'react-redux';
import {Outlet,Navigate} from "react-router-dom"

export default function PrivateRoute() {
    const userAuth = useSelector((state) => state.auth.isAuthenticated); 
    console.log("user auth is active")

 return userAuth ? <Outlet/> : <Navigate to = {"/signIn"}/>
 
}
