import { FacebookProvider, LoginButton } from 'react-facebook';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


export default function LoginButtonExample({ setAccountUpdate }) {
  const [instaButtonClicked, setInstaButtonClicked] = useState(false);
  const [igAuthResponse, setIgAuthResponse] = useState(null)
  const [igResRecieved, setIgResRecieved] = useState(false)
  const [selectedPage, setSelectedPage] = useState(null);
  const [fbButtonClicked, setfbButtonClicked] = useState(false);
  const [fbPage,setFbPage] = useState(null)
  const [igUser, setIgUser] = useState()
  const modalRef = useRef(null);

  function handleSuccess(response) {
    // Log the response and status
    setfbButtonClicked(true)
    console.log(response, response.status, response.authResponse.accessToken);
  
    // Store the access token in localStorage
    localStorage.setItem("fbAccess_Token", response.authResponse.accessToken);
  
    // Log the entire response again for debugging
    console.log(response);
  const userID = response.authResponse.userID
  const accessToken = response.authResponse.accessToken
    // Define the async function to fetch Facebook pages
    async function getFacebookPages() {
      try {
        // Make the POST request to the backend
        const res = await axios.get(`https://digisolvertechnologies.in/api/facebook/getPages?userID=${userID}&accessToken=${accessToken}`);
  
        // Check if the response is OK (status in the range 200-299)
       
  
        // Parse the response data as JSON
        const data =  res.data
        console.log(data); // Log the received data (pages info)
        setFbPage(data)
      } catch (error) {
        console.error('Error:', error); // Log any errors encountered
      }
     
    }
  
    // Call the async function to fetch pages
    getFacebookPages();
    
  }
  const addFbPage = async()=>{
    try {
      // Make a POST request to your backend API to save social media data
      const response = await axios.post('https://digisolvertechnologies.in/addSocialMedia', {
        platform:'Facebook',
        PlatformId: selectedPage.id,
        userName:selectedPage.name,
        accessToken:selectedPage.access_token
      
       
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      });
      console.log(response.data); // Log the response from the backend
      setfbButtonClicked(false)
      // Optionally, handle success or show a success message
    } catch (error) {
      console.error(error); // Handle errors
    }
  }
  

  function handleError(error) {
    console.log(error);
  }
  const parseAccessTokenFromIgUrl = () => {
    // const urlParams = new URLSearchParams(window.location.href);
    // const ig_access_token = urlParams.get('access_token');
    const decodedUrl = decodeURIComponent(window.location.href);

    const urlObj = new URL(decodedUrl);

    // Get the access token from the URL fragment
    try {
      const accessTokenString = urlObj.hash.split("=")[1];
      const parts = accessTokenString.split('&');
      const accessToken = parts[0].toString()
      console.log("Access Token:", accessToken);

      if (accessToken) {
        getIgProfile(accessToken)
        localStorage.setItem("ig_Access_Token", accessToken);
        console.log("Access token received ig:", accessToken);
      }
    } catch (error) {
      return;
    }
    // Find the part that contains the access token


  };
  const getIgProfile = async (accessToken) => {
    try {
      const response = await fetch(`https://digisolvertechnologies.in/igProfile?ig_accessToken=${accessToken}`)
      const data = await response.json()
      setIgAuthResponse(data)
      setIgResRecieved(true)

      console.log(data, igResRecieved)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    const parseAccessTokenFromUrlLinkedIn = async() => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessTokenLinkedIn = urlParams.get('accessToken');
      const userName = urlParams.get("userName")
      const platform = urlParams.get("platform")
      const PlatformId = urlParams.get("PlatformId")
      if (platform === 'LinkedIn') {
        try {
          // Make a POST request to your backend API to save social media data
          const response = await axios.post('https://digisolvertechnologies.in/addSocialMedia', {
            platform:platform,
            userName:userName,
            accessToken:accessTokenLinkedIn,
            PlatformId:PlatformId
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          });
          console.log(response.data); // Log the response from the backend
          // Optionally, handle success or show a success message
        } catch (error) {
          console.error(error); // Handle errors
        }
       
      }
    };
    parseAccessTokenFromUrlLinkedIn()
  },[window.location.href])

  useEffect(() => {



    const parseAccessTokenFromUrl = async() => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('accessToken');
      const userData = urlParams.get("screenName")
      const accessTokenSecret = urlParams.get("accessTokenSecret")
      if (accessTokenSecret) {
        try {
          // Make a POST request to your backend API to save social media data
          const response = await axios.post('https://digisolvertechnologies.in/addSocialMedia', {
            platform:'X',
            userName:userData,
            accessToken:accessToken,
            accessTokenSecret:accessTokenSecret
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          });
          console.log(response.data); // Log the response from the backend
          // Optionally, handle success or show a success message
        } catch (error) {
          console.error(error); // Handle errors
        }
        localStorage.setItem("X_accessToken", accessToken);
        localStorage.setItem('X_accessToken_secret',accessTokenSecret)
        console.log("Access token received:", accessToken);
      }
    };
    const xInitiated = localStorage.getItem("xInitiated")
    if (xInitiated) {
      parseAccessTokenFromUrl();
    }


    // Add event listener to handle clicks outside the modal
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setInstaButtonClicked(false);
        sessionStorage.setItem("instaButtonClicked", false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const igActive = sessionStorage.getItem("instaButtonClicked")

    if (igActive === "true") {
      setInstaButtonClicked(true)
      parseAccessTokenFromIgUrl()
    }

  }, [])



  const handleXlogin = async () => {
    try {
      const response = await fetch("https://digisolvertechnologies.in/loginX");
      const data = await response.json();
      const authUrl = data.authUrl;
      localStorage.setItem("xInitiated", true)
      console.log(authUrl)
      window.location.href = authUrl;
      
      console.log("accessToken Received");
    
    } catch (error) {
      console.log(error);
    }
  };
  const handleInstagramButtonClick = async () => {
    // Open the Facebook OAuth dialog URL in a new window

    try {
      sessionStorage.setItem("instaButtonClicked", true)
      const response = await fetch("https://digisolvertechnologies.in/instagramAuth");
      const data = await response.json()
      console.log("this is ig Data", data)
      const authUrl = data.authUrl;
      window.location.href = authUrl
    } catch (err) {

      console.log(err)
    }
    // window.open('https://www.facebook.com/v19.0/dialog/oauth?client_id=392084953510635&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=https://socialsynchub.com/user/console&response_type=token&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement');
  };
  const handlePageSelect = async () => {
    // Handle selected page here
    let accessToken = localStorage.getItem("ig_Access_Token")
    try {
      const response = await fetch(`https://digisolvertechnologies.in/verifyIg?pageId=${selectedPage}&ig_accessToken=${accessToken}`)
      const data = await response.json()
      if (data) {
        setIgUser(data.userData); // Assuming setIgUser is a state setter function
        // Assuming you want to store the username
        accessToken = data.token
        console.log('igUser set with:', data); // Log the data being set
        // console.log("igUser", igUser)
        try {
          // Make a POST request to your backend API to save social media data
          const response = await axios.post('https://digisolvertechnologies.in/addSocialMedia', {
            platform:'Instagram',
            PlatformId: data.userData.id,
            userName:data.userData.username,
            accessToken:localStorage.getItem("ig_Access_Token"),
           
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          });
          console.log(response.data); // Log the response from the backend
          // Optionally, handle success or show a success message
        } catch (error) {
          console.error(error); // Handle errors
        }
      } else {
        console.log('No data received from the server');
      }
    } catch (Error) {
      console.log(Error)
    }
    console.log("Selected Page:", selectedPage);
    // Perform actions based on selectedPage
  };
  const handleInstagramUserSelect = () => {
    if (igUser) {
      // setInstagramUsername(igUser);
     
      setAccountUpdate(false)
      localStorage.setItem("igUserUsername",igUser.username )
      // JSON.stringify(igUser.username)
      localStorage.setItem("igUserId", igUser.id)
      setInstaButtonClicked(false)
      sessionStorage.setItem("instaButtonClicked", false)
    }

  }
  const handleCancel = () => {
    setInstaButtonClicked(false)
    setfbButtonClicked(false)
    sessionStorage.setItem("instaButtonClicked", false)
  }

  const handleLinkedInlogin = async () => {
    try {
        const response = await axios.post('https://digisolvertechnologies.in/loginLinkedIn', {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        console.log('authUrl', response.data); // Assuming your server returns the authorization URL in the response
        // Redirect the user to the received authorization URL
        window.location.href = response.data.authorizationUrl;
    } catch (error) {
        console.log('error', error);
    }
}


  return (
    <div className=''>
      <div className='flex items-center gap-2'>
      <h3 className='p-4 text-xl font-bold text-gray-800'>Login with</h3>
      <div className='w-3/5 bg-violet-200 h-[1px]'></div>
      </div>
 
      <div className="flex  flex-wrap   gap-8  max-w-4xl my-6 px-4">
        {/* <FacebookProvider appId="392084953510635">
          <LoginButton
            scope="pages_show_list,instagram_basic,pages_manage_posts"
            onError={handleError}
            onSuccess={handleSuccess}
            className="w-full md:w-fit flex flex-row p-4 m-2 gap-2  rounded-md bg-gray-100 items-center shadow-md hover:shadow-none hover:z-20"
          >
           
            <img src='/img/socialmediaIcons/fbButton.png' alt="Facebook" className='w-12' />
            <p className='font-semibold text-md '>Facebook Page</p>
           
          </LoginButton>
        </FacebookProvider>

        <button onClick={() => setInstaButtonClicked(true)} className='w-full md:w-fit flex flex-row p-4 m-2 gap-2  rounded-md bg-gray-100 items-center shadow-md hover:shadow-none hover:z-20'><img src='/img/socialmediaIcons/instaButton.png' alt="Instagram" className='w-12'/><p className='font-semibold text-md'>Instagram Professional</p></button>  */}

        <button onClick={handleXlogin} className='w-full md:w-fit flex flex-row p-4 m-2 gap-2  rounded-md bg-gray-100 items-center shadow-md hover:shadow-none hover:z-20'> <img src='/img/socialmediaIcons/Xbutton.png' alt="X Button" className='w-12'/><p className='font-semibold text-md'>Formerly Twitter</p></button>
     
        <button onClick={handleLinkedInlogin} className='w-full md:w-fit flex flex-row p-4 m-2 gap-2  rounded-md bg-gray-100 items-center shadow-md hover:shadow-none hover:z-20'> <img src='/img/socialmediaIcons/linkedin.png' alt="linkedIn Button" className='w-12'/><p className='font-semibold text-md'>linkedIn</p></button>
      </div>

      {fbButtonClicked &&
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-30">
          <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div ref={modalRef} className="relative z-40">
          <Card style={{ width: '30rem' }} className='relative h-42 w-32 p-4 bg-biege-100 shadow-2xl   justify-center'>
              <p>Select facebook page which is connected to Instagram account</p>
              <div className='item-start'>
                {fbPage && fbPage.data.map((page, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`page-${index}`}
                      name="selectedPage"
                      value={page.id}
                      onChange={() => setSelectedPage(page)}
                    />
                    <label htmlFor={`page-${index}`}>{page.name}</label>
                  </div>

                ))}</div>
              <div className='flex flex-row-reverse gap-2 '>

                <Button variant="primary" onClick={() => addFbPage()}>Continue</Button>
                <Button variant='vicky' className='text-black' onClick={() => handleCancel()}>Cancel</Button>
              </div>
            </Card>

          </div>
        </div>
      }



      {instaButtonClicked &&
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-30">
          <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div ref={modalRef} className="relative z-40">
            {!igResRecieved ? (<Card style={{ width: '30rem' }} className='relative h-42 w-32 p-4 bg-biege-100 shadow-2xl  items-center justify-center'>
              <div className='flex flex-row p-4'>
                <Card.Img variant="top" src="/img/socialmediaIcons/fbButton.png" className='z-30 w-10 h-10' alt="Facebook" />
                <Card.Img variant="top" src="/img/socialmediaIcons/instaButton.png" className='z-10 w-10 h-10' alt="Instagram" />
              </div>
              <Card.Body className='z-0'>
                <Card.Text>
                  Only business Instagram which is connected with a facebook page
                </Card.Text>
                <div className='flex flex-row-reverse gap-2 '>

                  <Button variant="primary" onClick={() => handleInstagramButtonClick()}>Continue</Button>
                  <Button variant='vicky' className='text-black' onClick={() => handleCancel()}>Cancel</Button>
                </div>
              </Card.Body>
            </Card>) : !igUser ? (<Card style={{ width: '30rem' }} className='relative h-42 w-32 p-4 bg-biege-100 shadow-2xl   justify-center'>
              <p>Select facebook page which is connected to Instagram account</p>
              <div className='item-start'>
                {igAuthResponse !== null && igAuthResponse.data.map((ig, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`page-${index}`}
                      name="selectedPage"
                      value={ig.id}
                      onChange={() => setSelectedPage(ig.id)}
                    />
                    <label htmlFor={`page-${index}`}>{ig.name}</label>
                  </div>

                ))}</div>
              <div className='flex flex-row-reverse gap-2 '>

                <Button variant="primary" onClick={() => handlePageSelect()}>Continue</Button>
                <Button variant='vicky' className='text-black' onClick={() => handleCancel()}>Cancel</Button>
              </div>
            </Card>) : (<Card style={{ width: '30rem' }} className='relative h-42 w-32 p-4 bg-biege-100 shadow-2xl  items-center justify-center'>
              <div className='flex flex-row p-4'>
                <Card.Img variant="top" src="/img/socialmediaIcons/fbButton.png" className='z-30 w-10 h-10' alt="Facebook" />
                <Card.Img variant="top" src="/img/socialmediaIcons/instaButton.png" className='z-10 w-10 h-10' alt="Instagram" />
              </div>
              <p> {igUser.username}</p>
              <Card.Body className='z-0'>



                <div className='flex flex-row-reverse gap-2 '>

                  <Button variant="primary" onClick={() => handleInstagramUserSelect()}>Continue</Button>
                  <Button variant='vicky' className='text-black' onClick={() => handleCancel()}>Cancel</Button>
                </div>
              </Card.Body>
            </Card>)}

          </div>
        </div>
      }
    </div>
  );
}
