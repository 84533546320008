import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Payment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, plan } = location.state;

  const handlePayment = async () => {
    // Implement Razorpay payment integration here

    try {
      const paymentResponse = await axios.post('http://localhost:5000/process-payment', { plan });

      if (paymentResponse.data.success) {
        // Create the user account after successful payment
        await axios.post('http://localhost:5000/users', formData);
        navigate('/login');
      } else {
        console.error('Payment failed');
      }
    } catch (error) {
      console.error('An error occurred during payment:', error.message);
    }
  };

  return (
    <div className="payment-container">
      <h2>Payment</h2>
      <button onClick={handlePayment}>Proceed to Pay</button>
    </div>
  );
}
