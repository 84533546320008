import React, { useState } from "react";
import {useSelector,useDispatch} from "react-redux"
import { logout } from "../../store/slices/authSlice";
import { Dropdown } from "react-bootstrap";
import axios from "axios"
export default function UserNav() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  async function logoutAll(token) {
    try {
        const response = await axios.post('http://localhost:5000/users/logoutAll', {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log('Logout successful:', response.data);
    } catch (error) {
        console.error('Error logging out:', error);
    }
}
  const userName = useSelector((state)=> 
state.auth.userName)
const dispatch = useDispatch();
const signout = () =>{

  logoutAll(localStorage.getItem('accessToken'))

    dispatch(logout());
    localStorage.clear();
    sessionStorage.clear() 
      }

  return (
    <div className="relative inline-block">
      


<Dropdown>
      <Dropdown.Toggle  id="dropdown-basic">
        {userName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>signout()}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>




    </div>
  );
}
