// import React from 'react';
// import { Link } from 'react-router-dom';

// export default function Blogs() {
//   const blogPosts = [
//     {
//       id: 1,
//       title: 'Unlocking the Power of Social Media',
//       date: 'February 15, 2024',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//     },
//     {
//       id: 2,
//       title: 'Tips for Engaging Content Creation',
//       date: 'February 10, 2024',
//       content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
//     },
//     // Add more blog posts as needed
//   ];

//   return (
//     <section className="mt-20 mx-4 sm:mx-20">
//       <div className="max-w-4xl mx-auto">
//         <h2 className="text-4xl font-bold text-[#201515] mb-6">Blog</h2>

//         {blogPosts.map((post) => (
//           <Link key={post.id} className="mb-8" to = "/blogFull">
//             <h3 className="text-2xl font-bold text-[#201515] mb-2">{post.title}</h3>
//             <p className="text-gray-600 text-sm mb-2">{post.date}</p>
//             <p className="text-gray-700">{post.content}</p>
//           </Link>
//         ))}
//       </div>
//     </section>
//   );
// }





// Blogs.js
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';


const Blogs = ( {blogPosts} ) => {

  return (
    <>
    <Header/>
    <section className="mt-20 mx-4 sm:mx-20">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-[#201515] mb-6">Blog</h2>

        {blogPosts.map((post) => (
          <Link key={post.id} className="mb-8 max-h-2 overflow-hidden" to={`/blogFull/${post.id}`}>
            <h3 className="text-2xl font-bold text-[#201515] mb-2">{post.title}</h3>
            <p className="text-gray-600 text-sm mb-2">{post.date}</p>
            <p className="text-gray-700">{post.content}</p>
          </Link>
        ))}
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default Blogs;
