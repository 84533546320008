import React, { useState } from 'react';
import Dropdown from './dorpDownCheckBox';  // Adjust the path as needed

const AiSocialMediaForm = () => {
  const [formData, setFormData] = useState({
    topic: '',
    tone: '',
    goal: '',
    targetAudience: '',
    product: '',
    details: '',
    event: ''
  });
  const [promptResponse, setPromptResponse] = useState('');
  const [copied, setCopied] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: prevFormData[name] ? `${prevFormData[name]}, ${selectedOption}` : selectedOption
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://digisolvertechnologies.in/api/create-prompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log('dataAi', data.data.message.content);
      setPromptResponse(data.data.message.content);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(promptResponse);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const toneOptions = ['Informal', 'Professional', 'Inspirational'];
  const goalOptions = ['Increase Engagement', 'Promote a Product', 'Educate the Audience'];
  const audienceOptions = ['Young Professionals', 'Health Enthusiasts', 'Tech-savvy Individuals'];

  return (
    <div className="w-full mx-auto mt-4 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Create Social Media Content</h2>
      <div className='flex flex-row w-full'>
      <form onSubmit={handleSubmit} className='w-3/5'>
        <div className="mb-4">
          <label className=' text-black font-bold'>Main Topic/Theme</label>
          <input
            type="text"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="E.g., Sustainable Living, Fitness Tips"
            required
          />
        </div>
        <div className='flex flex-col'>
        <p className=' text-black font-bold'>Tone</p>
        <div className="mb-4 ">
         
         <input
           type="text"
           name="tone"
           value={formData.tone}
           onChange={handleChange}
           className="w-full mt-2 p-2 border rounded"
           placeholder="Enter custom tone"
         />
          <Dropdown
           label="Tone"
           options={toneOptions}
           selectedOptions={formData.tone}
           onSelect={(option) => handleSelectChange('tone', option)}
         />
       </div>
        </div>
       
        <div className='flex flex-col'>
        <p className=' text-black font-bold'>Goal</p>
        <div className="mb-4 ">
         
         <input
           type="text"
           name="goal"
           value={formData.goal}
           onChange={handleChange}
           className="w-full mt-2 p-2 border rounded"
           placeholder="Enter custom goal"
         />
          <Dropdown
           label="Goal"
           options={goalOptions}
           selectedOptions={formData.goal}
           onSelect={(option) => handleSelectChange('goal', option)}
         />
       </div>
        </div>
       
        <div className='flex flex-col'>
          <p className=' text-black font-bold'>Target Audience</p>
        <div className="mb-4  ">
         
         <input
           type="text"
           name="targetAudience"
           value={formData.targetAudience}
           onChange={handleChange}
           className="w-full mt-2 p-2 border rounded"
           placeholder="Enter custom audience"
         />
          <Dropdown
           label="Target Audience"
           options={audienceOptions}
           selectedOptions={formData.targetAudience}
           onSelect={(option) => handleSelectChange('targetAudience', option)}
         />
       </div>
        </div>
  
        <div className="mb-4">
          <label className="block text-gray-700">Product/Service (Optional)</label>
          <input
            type="text"
            name="product"
            value={formData.product}
            onChange={handleChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="E.g., Eco-friendly Water Bottle"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Details/Features (Optional)</label>
          <input
            type="text"
            name="details"
            value={formData.details}
            onChange={handleChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="E.g., Made from Recycled Materials"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Event (Optional)</label>
          <input
            type="text"
            name="event"
            value={formData.event}
            onChange={handleChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="E.g., New Yoga Class"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
        >
          Submit
        </button>
      </form>

      {promptResponse && (
        <div className="mt-4 p-4 w-2/5 flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-2">Generated Prompt:</h3>
          <div className="flex flex-col  items-center justify-center gap-1 w-full ">
            <textarea
              type="text"
              // cols={4}
              rows={4}
              value={promptResponse}
              readOnly
              className="w-full mt-2 p-2 border rounded"
            />
            <button
              className="mt-2 mr-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={handleCopyToClipboard}
            >
              {copied ? 'Copied!' : 'Copy to Clipboard'}
            </button>
          </div>
        </div>
      )}
      </div>
     
    </div>
  );
};

export default AiSocialMediaForm;
