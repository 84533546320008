import React, { useState,useEffect } from 'react'
import { Link,useLocation } from 'react-router-dom';
import Header from '../../components/header/header';
import HeaderConsole from '../../components/header/headerConsole';
import UserNav from '../../components/header/userNav';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import PremiumButton from '../../components/button/premiumButton';
import NavMobile from '../../components/nav/navMobile';


export default function Dashboard() {
    const [activeTab,setActiveTab] = useState(0)
    const [lgTabView,setLgTabView] = useState(false)
    const [menuOpen,setMenuOpen] = useState(false)
    const location = useLocation();
    let screenWidth = window.innerWidth;
    const navs = [
        {
            navIcon: <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" className='w-5 '>
                <path d="m21.5,9.5c-1.025,0-1.903.618-2.289,1.5h-3.352c-.176-.677-.515-1.286-.987-1.774l1.747-2.854c.954.131,1.939-.295,2.474-1.169.721-1.177.351-2.716-.827-3.438-1.177-.721-2.716-.351-3.438.827-.535.874-.467,1.945.084,2.735l-1.754,2.864c-.369-.113-.753-.191-1.159-.191s-.79.079-1.16.192l-1.754-2.864c.551-.79.619-1.862.084-2.736-.721-1.177-2.26-1.548-3.438-.827-1.177.721-1.548,2.26-.827,3.438.535.874,1.52,1.3,2.474,1.169l1.747,2.854c-.471.488-.811,1.097-.986,1.773h-3.353c-.386-.882-1.264-1.5-2.289-1.5C1.119,9.5,0,10.619,0,12s1.119,2.5,2.5,2.5c1.024,0,1.903-.618,2.289-1.5h3.354c.176.676.515,1.286.986,1.774l-1.747,2.854c-.954-.131-1.939.295-2.474,1.169-.721,1.177-.351,2.717.827,3.438,1.177.721,2.717.351,3.438-.826.535-.874.467-1.946-.084-2.736l1.753-2.863c.37.113.754.192,1.16.192s.79-.079,1.159-.192l1.753,2.863c-.551.79-.619,1.862-.084,2.736.721,1.177,2.26,1.548,3.438.826,1.178-.721,1.548-2.26.827-3.438-.535-.874-1.521-1.3-2.474-1.169l-1.747-2.853c.472-.488.811-1.098.987-1.774h3.353c.386.882,1.264,1.5,2.289,1.5,1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5Zm-11.5,2.5c0-1.103.897-2,2-2s2,.897,2,2-.897,2-2,2-2-.897-2-2Z" />
            </svg>,
            navTitle: 'Channels',
            navRoute: '/user/socialAccounts'
        },
        {
            navIcon: <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" className='w-5'><path d="M18.385,7.373a1.128,1.128,0,0,1-.751-.748h0a8,8,0,1,0-15.1,5.237A1.046,1.046,0,0,1,2.223,13.1,5.5,5.5,0,0,0,.057,18.3,5.622,5.622,0,0,0,5.683,23H14V15.414l-1.293,1.293a1,1,0,0,1-1.414-1.414l1.586-1.586a3,3,0,0,1,4.242,0l1.586,1.586a1,1,0,1,1-1.414,1.414L16,15.414v7.573a8.181,8.181,0,0,0,7.971-7.309A7.964,7.964,0,0,0,18.385,7.373Z" /></svg>,
            navTitle: 'Publish',
            navRoute: '/user/deployTab'
        },
        {
            navIcon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="schedule" className='w-5'><path d="M44 5h-2V1a1 1 0 0 0-2 0v7.28a2 2 0 1 1-2 0V5h-2V1a1 1 0 0 0-2 0v7.28a2 2 0 1 1-2 0V5H17V1a1 1 0 0 0-2 0v7.28a2 2 0 1 1-2 0V5h-2V1a1 1 0 0 0-2 0v7.28a2 2 0 1 1-2 0V5H4a3 3 0 0 0-3 3v7h46V8a3 3 0 0 0-3-3ZM37 40h4v2h-4zM7 22h4v2H7zM27 31h4v2h-4zM17 31h4v2h-4zM27 40h4v2h-4zM17 22h4v2h-4zM37 22h4v2h-4zM7 40h4v2H7z"></path><path d="M1 44a3 3 0 0 0 3 3h40a3 3 0 0 0 3-3V17H1Zm34-23a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Zm1.71 10.29 1.29 1.3 3.29-3.3a1 1 0 0 1 1.42 1.42l-4 4a1 1 0 0 1-1.42 0l-2-2a1 1 0 0 1 1.42-1.42ZM35 39a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Zm-8.29-16.71 1.29 1.3 3.29-3.3a1 1 0 0 1 1.42 1.42l-4 4a1 1 0 0 1-1.42 0l-2-2a1 1 0 0 1 1.42-1.42ZM25 30a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Zm0 9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1ZM15 21a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Zm0 9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Zm1.71 10.29 1.29 1.3 3.29-3.3a1 1 0 0 1 1.42 1.42l-4 4a1 1 0 0 1-1.42 0l-2-2a1 1 0 0 1 1.42-1.42ZM5 21a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm0 9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm0 9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Z"></path><path d="M7 31h4v2H7z"></path></svg>,
            navTitle: 'Planner',
            navRoute: '/user/planner'
        },

    ];
    const navAccount = [
        // {navIcon: <HomeIcon/> , navTitle: 'Home', navRoute: '/' },
       
        {navIcon:<AccountCircleIcon/>, navTitle:  <UserNav />, navRoute: null },
      ];
    useEffect(() => {
        // Find the index of the current route in navs array
        const currentTab = navs.findIndex(nav => location.pathname === nav.navRoute);
        if (currentTab !== -1) {
            setActiveTab(currentTab);
        }
    }, [location.pathname]);


    return (
        //  
        <div className={`flex flex-col max-w-[400px]   bgAppPrimary   shadow-xl px-2 py-8 items-center gap-3  h-full` } id='dashboard' onMouseEnter={() => setLgTabView(true)} onMouseLeave={() => setLgTabView(false)}>
        <div className=' flex flex-col gap-3 items-start'>
            <Link >
         <img src='/logo.png' className='w-36 mb-20' alt='Logo' />
            </Link>
            {navAccount.map((nav, index) => (
                        <NavLink className='p-2 text-black rounded list-none ' to={nav.navRoute} key={index}>
                            <div className='flex flex-row gap-3'>
                                <div>{nav.navIcon}</div>
                                { <p>{nav.navTitle}</p>}
                            </div>
                        </NavLink>
                       
                    ))}
        </div>
        
   
            <div className={`flex flex-col h-screen mt-6  `}>
                <div className='flex flex-col items-start  gap-3'>
                    {navs.map((nav, index) => (
                        <Link key={index} to={nav.navRoute} className={`flex flex-row gap-3 text-lg items-center py-3 px-4 rounded-sm w-full h-9 ${activeTab === index ? 'bgprimary6' : ''} hover:bg-gray-50`}>
                            <div>{nav.navIcon}</div>
                            <p>{nav.navTitle}</p>
                        </Link>
                    ))}
                </div>
                <nav className='flex flex-col rounded'>
                <PremiumButton/>
                
                    
                </nav>
            </div>
        
      
        
    </div>
    )
}
