const blogPosts = [
    {
      id: 1,
      title: 'Empower Your Social Media Presence with SocialSyncHub',
      date: 'February 15, 2024',
      content: `In today's dynamic digital landscape, social media plays a pivotal role in brand visibility and engagement. [Your App Name] is here to revolutionize the way you manage your social media presence.
      Certainly! Below are four blog post ideas highlighting the features and future goals of your social media management app.
Blog Post 1: "Empower Your Social Media Presence with [Your App Name]"

In today's dynamic digital landscape, social media plays a pivotal role in brand visibility and engagement. [Your App Name] is here to revolutionize the way you manage your social media presence.

Key Features:

    Unified Dashboard: Gain a comprehensive view of all your social media accounts in one centralized dashboard. Streamline your workflow and stay organized effortlessly.

    Scheduled Posting: Plan your content ahead of time and schedule posts across various platforms. No more last-minute rushes – maintain a consistent posting schedule.

    Analytics Insights: Track the performance of your content with detailed analytics. Understand your audience, measure engagement, and make data-driven decisions.

Future Goals:
We're committed to enhancing your experience with continuous updates and new features. Our future goals include:

    Advanced Analytics: Expect more in-depth analytics with actionable insights to help you optimize your content strategy.

    Collaborative Tools: Stay tuned for collaborative features that enable seamless teamwork on social media campaigns.

    Integration with Emerging Platforms: As new social media platforms emerge, [Your App Name] will evolve to support integration, ensuring you stay ahead in the social media landscape.`,
    },
    {
      id: 2,
      title: 'Tips for Engaging Content Creation',
      date: 'February 10, 2024',
      content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
  // Add more blog posts as needed
 ];
 export default blogPosts;