import React, { useState } from 'react'
import Pricing from '../pricing'

export default function PremiumButton() {
  const [premiumUser, setPremiumUser] = useState(true)
  const [clicked, setClicked] = useState(false)

  const handlePremiumPayment = () => {
    setClicked(true)
    
};

  



  return (
    <>
    <div className=''>
      {clicked ? (
        <div className='fixed top-4 z-50 left-1/4 border-orange-400 border-2 flex flex-col bg-white'>
          <div className='flex flex-row-reverse'> <svg onClick={()=>setClicked(false)} className='cursor-pointer'  xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="close"><path fill="none" d="M0 0h24v24H0Z"></path><path fill="#525863" d="M15.741 17.61 12 13.87l-3.742 3.74a1.323 1.323 0 0 1-1.873-1.869L10.128 12 6.385 8.258a1.324 1.324 0 0 1 1.873-1.873L12 10.127l3.741-3.742a1.324 1.324 0 0 1 1.873 1.873L13.872 12l3.742 3.741a1.323 1.323 0 0 1-1.873 1.869Z"></path></svg></div>
<Pricing />
        </div>
        
      ) : (
        !premiumUser && (
          <button
            onClick={handlePremiumPayment}
            className="px-4 py-2 bg-orange-600 text-white rounded shadow-md hover:bg-orange-700 focus:outline-none"
          >
            Premium
          </button>
        )
      )}
    </div>
    </>
  )
}
