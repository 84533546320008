

import React, { useEffect, useState } from 'react';
import { FacebookProvider, ShareButton } from 'react-facebook';
import LoginButtonExample from '../../components/fbLogin';
import axios from 'axios';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { json, Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Channels({accountUpdate}) {
    const [pages, setPages] = useState(null);
    const [xAccount, setXAccount] = useState(null)
    const fbAccessToken = localStorage.getItem('fbAccess_Token');
    const xAccessToken = localStorage.getItem("X_Access_Token")
    const [fbPermission, setFbPermission] = useState(false);
 
    const [selectedFbPage, setSelectedFbPage] = useState('');
    const [instagramUsername, setInstagramUsername] = useState(null)
    // const igUsername = localStorage.getItem("igUserUsername")

    const [socialMedia, setSocialMedia] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [deletePlatform,setDeletePlatform] = useState('')
    const handleClickOpen = (channel) => {
setDeletePlatform(channel)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const Idresponse = await axios.get(`https://graph.facebook.com/v19.0/me?fields=id,name&access_token=${fbAccessToken}`);
                const id = Idresponse.data.id;

                const pageShowListResponse = await axios.get(`https://graph.facebook.com/${id}/accounts?access_token=${fbAccessToken}`);
                const pagesResponse = pageShowListResponse.data.data;

                console.log(pagesResponse);
                setPages(pagesResponse);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        if (fbAccessToken) {
            fetchData();
        }
        const fetchSocialMedia = async () => {
            try {
                const response = await fetch('https://digisolvertechnologies.in/socialMedia', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}` // Assuming you store the token in localStorage
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('socialMedia',data)
                    
                    setSocialMedia(data);
                    sessionStorage.setItem('socialMedia',JSON.stringify(data))
                } else {
                    console.error('Failed to fetch social media data');
                }
            } catch (error) {
                console.error('Error fetching social media data:', error);
            }
        };

        fetchSocialMedia();

    }, [window.location.href,open,accountUpdate]);
   
//     useEffect(() => {
//         const queryParameters = new URLSearchParams(window.location.search)
//         //   const userData = JSON.parse(queryParameters.get("screenName"))
//         // const accessToken = queryParameters.get("accessToken")
//         // const accessTokenSecret = queryParameters.get("accessTokenSecret")
//         const userData = queryParameters.get("screenName")
//         const xUser = localStorage.getItem("xUser")
//         if (userData) {
//             setXAccount(userData)
//             localStorage.setItem("xUser", userData)
//         } else if (xUser) {
//             setXAccount(xUser)
//         }


//         // localStorage.setItem("X_accessToken", accessToken)
//         // localStorage.setItem("X_accessToken_secret", accessTokenSecret)
//         // console.log(userData, accessToken, accessTokenSecret)
//     }, [window.location.search])
//     useEffect(() => {
//         const access_tokenIg = localStorage.getItem('ig_Access_Token')
//         if(access_tokenIg ){
//             if(access_tokenIg !== '' && access_tokenIg !== '_')
//             setIg_Access(true)
//         }
//         const igUsername = localStorage.getItem("igUserUsername")
//         const igUserId = localStorage.getItem("igUserId")
//         if (igUsername !== '' && igUsername !== "") {
//             setInstagramUsername({ username: igUsername, id: igUserId });
//         }
//     }, [window.location.search])
//     useEffect(()=>{
// let xPermissionCheck = localStorage.getItem('X_Permission')
// let igPermissionCheck = localStorage.getItem('ig_Permission')
// if(xPermissionCheck){
//     if(xPermissionCheck === 'true'){
//     setXPermission(true)
//     }else{
//         setXPermission(false)
//     }
// }
// if(igPermissionCheck){
//     if(igPermissionCheck === 'true'){
//         setIgPermission(true)
//         }else{
//             setIgPermission(false)
//         }
// }
//     },[])
    const handlePageSelection = (pageId, index) => {
        console.log(selectedFbPage === pageId)
        if (selectedFbPage === pageId) {
            console.log("no page selected")
            setSelectedFbPage('');
            setFbPermission(false);
            localStorage.setItem("fbPermission", false);
        } else {
            console.log("no page selected123")
            setSelectedFbPage(pages[index].id);
            setFbPermission(true);
            localStorage.setItem("fbPermission", true)
        }

        localStorage.setItem("fbPageId", pageId)
        localStorage.setItem("fbPageToken", pages[index].access_token)
        console.log(pages[index].name)
    }
   
    const channelIcon = (channel)=>{
   if(channel === 'X'){
    return '/img/socialmediaIcons/Xbutton.png'
   }
   if(channel === 'Instagram'){
    return '/img/socialmediaIcons/instaButton.png'
   }
   if(channel === 'LinkedIn'){
    return '/img/socialmediaIcons/linkedin.png'
   }
   if(channel === 'Facebook'){
    return '/img/socialmediaIcons/fbButton.png'
   }
    }
    const deleteSocialMediaData = async (platform) => {
        try {
            const accessToken = localStorage.getItem('accessToken'); // Your authentication token
            const response = await fetch(`https://digisolvertechnologies.in/deleteSocialMedia/${platform}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`, // Include the authentication token in the request headers
                    'Content-Type': 'application/json'
                }
            });
            console.log('deletign')
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete social media data');
            }
    
            const responseData = await response.json();
            console.log(responseData.message); // Log success message
            // Perform any additional actions after successful deletion
        } catch (error) {
            console.error(error.message);
            // Handle error
        }
    };
    const handleRemoveAccounnt = async()=>{
     await deleteSocialMediaData(deletePlatform)
     setOpen(false)
    }

    return (
        <div className='flex flex-col justify-start bg-white '> 
       
       <div className='flex items-center gap-2'>
      <h3 className='p-4 text-xl font-bold text-gray-800'>Available Channels</h3>
      <div className='w-3/5 bg-violet-200 h-[1px]'></div>
      </div>
 
         
            <Stack gap={3} className=' flex flex-col  my-3    overflow-y-scroll  '>

    {socialMedia.length > 0 ?<> {socialMedia.map((channel,index)=>(
    <div className='flex flex-row items-center w-full justify-between gap-3 cursor-pointer px-4 hover:bg-gray-100 py-2  hover:z-20' key={index}>
<div className='w-3/5'>{channel.userName}</div>
<img src={channelIcon(channel.platform)} className='w-6 rounded-2xl'/>
<DeleteIcon  onClick={()=>handleClickOpen(channel.platform)} className='hover:scale-125 cursor-pointer'/>

    </div>
    
   ))}</> :<div className='px-4'>No channel connected.</div>}
   

   
               
            </Stack>
            <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, you want to remove account ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button  onClick={()=>handleRemoveAccounnt()}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
      
        </div>
    );
}
