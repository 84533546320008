import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-8 bg-white shadow-md mt-10" id='TermsOfService'> 
            <h1 className="text-3xl font-bold text-center mb-8">Terms of Service</h1>
            <p className="text-gray-600"><strong>Effective Date:</strong> June 29 2024</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
            <p className="text-gray-600">
                Welcome to SocialSyncHub ("we", "our", "us"). These Terms of Service ("Terms") govern your use of our social media management application (the "App"). By accessing or using the App, you agree to comply with and be bound by these Terms.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">2. Use of the App</h2>
            <p className="text-gray-600">
                You agree to use the App only for lawful purposes and in accordance with these Terms. You are responsible for all activities that occur under your account and for maintaining the confidentiality of your account and password.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">3. User Accounts</h2>
            <p className="text-gray-600">
                To use certain features of the App, you must create an account. You agree to provide accurate, complete, and current information during the registration process and to update such information as necessary.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">4. Content</h2>
            <p className="text-gray-600">
                You retain ownership of any content you post through the App. By posting content, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, and display such content in connection with the operation of the App.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">5. Prohibited Activities</h2>
            <p className="text-gray-600">You agree not to:</p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
                <li>Use the App for any illegal purpose or in violation of any local, state, national, or international law.</li>
                <li>Post or transmit any content that is unlawful, defamatory, obscene, offensive, or otherwise objectionable.</li>
                <li>Use the App to harass, abuse, or harm another person.</li>
                <li>Impersonate any person or entity or misrepresent your affiliation with a person or entity.</li>
                <li>Interfere with or disrupt the operation of the App or any servers or networks connected to the App.</li>
                <li>Attempt to gain unauthorized access to any portion of the App or any other accounts, computer systems, or networks connected to the App.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">6. Termination</h2>
            <p className="text-gray-600">
                We reserve the right to terminate or suspend your access to the App at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the App will immediately cease.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">7. Disclaimer of Warranties</h2>
            <p className="text-gray-600">
                The App is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the App, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">8. Limitation of Liability</h2>
            <p className="text-gray-600">
                To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; and (iii) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our App by any third party.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">9. Governing Law</h2>
            <p className="text-gray-600">
                These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">10. Changes to Terms</h2>
            <p className="text-gray-600">
                We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website and in the App. You are advised to review these Terms periodically for any changes. By continuing to use the App after any changes become effective, you agree to be bound by the revised Terms.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">11. Contact Us</h2>
            <p className="text-gray-600">
                If you have any questions or concerns about these Terms, please contact us at [Your Contact Information].
            </p>

            <p className="text-gray-600">
                By using our App, you acknowledge that you have read and understood these Terms of Service.
            </p>
        </div>
    );
};

export default TermsOfService;
