

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CloseButton } from "@chakra-ui/close-button"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { plannerUpdate } from '../../store/slices/plannerUpdateSlice';
import Notification from '../../components/notification/notification';
import ChannelSelect from './deploy/channelSelect';
import ChatAi from './gpt/chatAi';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AiSide from './gpt/aiSide';
import XPostView from '../../components/contentCreation/postView/XPostView';
import LinkedInPost from '../../components/contentCreation/postView/linkedinPostView';
import FullpostView from '../../components/contentCreation/postView/fullpostView';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';
import BlurBarrier from '../../components/blurBarrier';
export default function DeployPost(props) {
    const fbPageId = localStorage.getItem("fbPageId");
    const fbPageToken = localStorage.getItem("fbPageToken");

    const [posts, setPosts] = useState([]);
    const [selectedPostMedia, setSelectedPostMedia] = useState([]);
    const [selectedPostContent, setSelectedPostContent] = useState('');
    const [isLoading, setLoading] = useState(false);
    // const [files, setFiles] = useState([])
    let [imageIndex, setImageIndex] = useState(0)
    const [mediaType, setMediaType] = useState(null)
    const [dateTime, setDateTime] = useState()
    const dispatch = useDispatch()
    const [uploading, setUploading] = useState(false)

    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [selectedFiles1, setSelectedFiles1] = useState([]);
    const [apiResponse, setApiResponse] = useState({})
    const [propsInitiated, setPropsInitiated] = useState(false)
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [emojiButton, setEmojiButton] = useState(false)
    const [mobilePostView, setMobilePostView] = useState(false)
    let screenWidth = window.innerWidth;
    // Function to receive selected channels from ChannelSelect component
    const handleChannelSelect = (selectedChannels) => {
        const socialMediaString = sessionStorage.getItem('socialMedia')
        let socialMedia = JSON.parse(socialMediaString)
        
        const updatedSelectedChannels = selectedChannels.map((channelId) => {
            return socialMedia.find((item) => item._id === channelId);
        });

        // Update the state with the new selected channels
        setSelectedChannels(updatedSelectedChannels);

        console.log('selectedChannels', updatedSelectedChannels);
    };
    const handleMediaClick = (index) => {
        setCurrentMediaIndex(index);
    };
    useEffect(() => {
        if (props.schedule) {
            // setPropsInitiated(true)
            const schedule = props.schedule.schedule

            //    setSelectedPostMedia(schedule.media)
            setSelectedChannels(schedule.socialMedia)

            setSelectedPostContent(schedule.caption)
            //  


            const date = dayjs(schedule.dateTime)

            setDateTime(date)

        }
        if (props.dateTime) {
            const date = dayjs(props.dateTime)
            console.log(date)
            setDateTime(date)
        }
    }, [props])
    useEffect(() => {

        const fetchSavedPosts = async () => {
            console.log('screenWidth', screenWidth)
            try {
                const accessToken = localStorage.getItem('accessToken');

                if (!accessToken) {
                    throw new Error('Access token not found');
                }

                const response = await axios.get('https://digisolvertechnologies.in/posts', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                console.log('Raw Response:', response);

                if (!response.data) {
                    throw new Error('Failed to fetch posts');
                }

                const data = response.data;
                setPosts(data);
                console.log('Fetched Posts:', data);
            } catch (error) {
                console.error('Error fetching posts:', error.message);
            }
        };

        fetchSavedPosts();
    }, []);


    useEffect(() => {
        if (!propsInitiated && props.schedule) {

            setSelectedPostMedia(props.schedule.schedule.media);


            setPropsInitiated(true)
        }
    }, [props]);
    const handleSharePost = async () => {

        if (!selectedPostContent && selectedPostMedia.length === 0) {
            console.error('No selected post or post images available');
            return;
        }
        setLoading(true)

        try {
            const tweetContent = selectedPostContent;
            const caption = selectedPostContent;

            const fbPermission = JSON.parse(localStorage.getItem("fbPermission"));

            let postDateTime;
            function base64ToBlob(base64Data, contentType) {
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                return new Blob([byteArray], { type: contentType });
            }
            function convertBase64ArrayToBlobArray(base64Array) {
                console.log('happy')
                return base64Array.map(item => ({
                    filename: `${Date.now()}_${Math.floor(Math.random() * 1000)}.${item.fileType.startsWith('image/') ? 'png' : 'mp4'}`,
                    blob: base64ToBlob(item.url, item.fileType),
                    fileType: item.fileType,
                }));
            }
            // const imageData = selectedPostMedia;
            const imageData = convertBase64ArrayToBlobArray(selectedPostMedia);
            console.log('this is new image data', imageData)
            if (dateTime) {
                postDateTime = dateTime.$d
                console.log('timeDate', postDateTime, dateTime['$d'].toISOString())
            }

            console.log('check condition', props, postDateTime)
            if (props.schedule) {
                console.log('editng')
                let scheduleData = {
                    socialMedia: selectedChannels,
                    dateTime: postDateTime,
                    caption: caption,
                    media: imageData,
                    cover: imageData[0]
                }
                console.log(postDateTime)
                let accessToken = localStorage.getItem('accessToken')
                console.log(props.schedule.id)
                try {
                    const scheduleResponse = await axios.put(
                        `https://digisolvertechnologies.in/api/schedules/${props.schedule.id}`,
                        scheduleData,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    console.log('Schedule edited successfully:', scheduleResponse.data);
                    setApiResponse({ message: `Schedule edited for ${dateTime}  successfully`, status: 'success' })
                } catch (error) {
                    console.log('error scheduling post', error)
                    setApiResponse({ message: 'Error scheduling post', status: 'error' })
                }
            }
            const s3response = await axios.post('https://digisolvertechnologies.in/generate-presigned-urls', {
                files: imageData.map(file => ({
                    filename: file.filename,
                    fileType: file.fileType
                }))
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }

            });

            const { presignedUrls } = s3response.data;
            console.log('pre-signed url', presignedUrls)
            const uploadPromises = imageData.map((file, index) => {
                const { url } = presignedUrls[index];
                const blob = file.blob;

                const uploadConfig = {
                    headers: {
                        'Content-Type': file.fileType,
                    },
                    //   onUploadProgress: (progressEvent) => {
                    //     const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    //     setUploadProgress(prev => ({ ...prev, [file.filename]: progress }));
                    //   },
                };

                return axios.put(url, blob, uploadConfig);
            });

            await Promise.all(uploadPromises);

            //   alert('Files uploaded successfully!');
            let imageUrlArray = []
            let imageUrlXArray = []
            presignedUrls.map((url) => {
                imageUrlArray.push(url.distributionUrl)
                imageUrlXArray.push(url.key)
            })
            console.log('url array', imageUrlArray, imageUrlXArray)
            const scheduleMedia = { imageUrlArray: imageUrlArray, imageUrlXArray: imageUrlXArray }
            if (dateTime) {


                let scheduleData = {
                    socialMedia: selectedChannels,
                    dateTime: postDateTime,
                    caption: caption,
                    media: scheduleMedia,
                    cover: imageUrlArray[0]
                }
                console.log(postDateTime)
                let accessToken = localStorage.getItem('accessToken')
                try {
                    const scheduleResponse = await axios.post(
                        'https://digisolvertechnologies.in/api/schedules',
                        scheduleData,
                        {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    console.log('Schedule created successfully:', scheduleResponse.data);
                    setApiResponse({ message: `Schedule at ${dateTime} created successfully`, status: 'success' })
                } catch (error) {
                    console.log('error scheduling post', error)
                    setApiResponse({ message: 'Error scheduling post', status: 'error' })
                }
            } else if (!dateTime) {

                // const response = await axios.post('https://digisolvertechnologies.in/upload', { imageData });

                // console.log("response for image", response)
                // const imageUrlArray = response.data.imageUrlArray
                // const imageUrlXArray = response.data.imageUrlXArray
                let socialMediaTokens = []
               
                try {
                    // https://digisolvertechnologies.in
                    const response = await axios.post(
                        'https://digisolvertechnologies.in/deployPost',
                        {
                            channels: selectedChannels,
                            media: scheduleMedia,
                            caption: caption,
                        
                        },{
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
        
                    console.log(' post shared successfully:', response.data);
                  
        
                    // Handle success, if needed
                } catch (error) {
                    console.error('Error posting on LinkedIn', error);
                  
                    // Handle error, if needed
                }
              
         
                if (fbPermission) {
                    const url = `https://graph.facebook.com/v19.0/${fbPageId}/photos`;
                    const formData = new FormData();
                    formData.append('url', imageUrlArray);
                    formData.append('message', selectedPostContent);

                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fbPageToken}`,
                    };
                    const response = await axios.post(url, formData, { headers });
                    console.log('Post uploaded successfully:', response.data);
                }
            }





            //   const plannerUpdate = useSelector((state) => state.plannerUpdate.isPlannerUpdated);
            dispatch(plannerUpdate())
            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.error('Error uploading photo:', error.message);
        }
    };

    const postLinkedIn = async (channel, caption, imageUrlXArray) => {
        try {

            const response = await axios.post(
                'https://digisolvertechnologies.in/share/linkedInPost',
                {
                    platform: channel.platform,
                    imageUrls: imageUrlXArray,
                    commentry: caption,
                    PlatformId: channel.PlatformId
                }
            );

            console.log('LinkedIn post shared successfully:', response.data);
            if (response.data.id) {
                // console.log('id',response.data.id)
                setApiResponse({ message: 'LinkedIn post uploaded successfully', status: 'success' })
            } else {
                setApiResponse({ message: `LinkedIn: ${response.data}`, status: 'error' })
            }

            // Handle success, if needed
        } catch (error) {
            console.error('Error posting on LinkedIn', error);
            setApiResponse({ message: 'Error posting on LinkedIn', status: 'error' })
            // Handle error, if needed
        }
    };
    const postTweet = async (channel, tweetContent, imageUrlXArray) => {
        try {
            console.log("img url for tweet", imageUrlXArray)
            const response = await axios.post(
                'https://digisolvertechnologies.in/tweetPost',
                {
                   platform:channel.platform,
                    tweetContent: tweetContent,
                    imageUrlX: imageUrlXArray
                }
            );

            console.log('Tweet posted successfully:', response.data);
            if (response.data.data.id) {
                // console.log('id',response.data.id)
                setApiResponse({ message: 'Tweet uploaded successfully', status: 'success' })
            } else {
                setApiResponse({ message: `X: ${response.data.detail}`, status: 'error' })
            }

            // Handle success, if needed
        } catch (error) {
            console.error('Error posting tweet:', error);
            setApiResponse({ message: 'Error posting tweet', status: 'error' })
            // Handle error, if needed
        }
    };
    const shareIgPost = async (channel, caption, imageUrlArray, postDateTime) => {

        try {
            console.log("img url for igPost", channel.PlatformId)
            const response = await axios.post(
                'https://digisolvertechnologies.in/shareIgPost',
                {
                    platform:channel.platform,
                    igId: channel.PlatformId,
                    caption: caption,
                    imageUrlArray: imageUrlArray,
                    mediaType: mediaType
                }
            );

            console.log('ig posted successfully:', response.data);
            setApiResponse({ message: 'Ig post uploaded successfully', status: 'success' })
            // Handle success, if needed
        } catch (error) {
            console.error('Error posting ig:', error);
            setApiResponse({ message: 'Error uploading to Instagram', status: 'error' })
            // Handle error, if needed
        }


    };
    const handleTextArea = (e) => {
        setSelectedPostContent(e.target.value)
    }
    const handleFileChange = (e) => {
        // setUploading(!uploading)
        const files = e.target.files;
        console.log('files', files)

        setSelectedFiles1(files);


    }
    const handleCutMedia = (index) => {
        const updatedFiles = [...selectedPostMedia];
        updatedFiles.splice(index, 1);
        setSelectedPostMedia(updatedFiles);
        setSelectedFiles1(updatedFiles)
        
            setCurrentMediaIndex(0)
        


    };

    useEffect(() => {
        if (selectedFiles1.length === 0) return;

        const readFile = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const base64String = event.target.result;
                    const base64Data = base64String.substring(base64String.indexOf(",") + 1);
                    resolve(base64Data);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };

        const processFiles = async () => {
            const updatedSelectedPostMedia = [];
            for (let fileIndex = 0; fileIndex < selectedFiles1.length; fileIndex++) {
                try {
                    console.log('processing', selectedFiles1[fileIndex])
                    const base64Data = await readFile(selectedFiles1[fileIndex]);
                    updatedSelectedPostMedia.push({ url: base64Data, fileType: selectedFiles1[fileIndex].type });
                } catch (error) {
                    console.error('Error reading file:', error);
                }
            }
            console.log('uploading to selectedpostmedia')
            setSelectedPostMedia(prevMedia => [...prevMedia, ...updatedSelectedPostMedia]);

        };
        console.log('selectedFiles1', selectedFiles1, selectedPostMedia)
        processFiles();
        console.log('smp', selectedPostMedia)

    }, [selectedFiles1]);

    const handleImageChangeMinus = () => {
        if (imageIndex === 0) {
            console.log("you cant go back")
            return
        } else {
            setImageIndex(imageIndex - 1)
            console.log('one stepBack', imageIndex)
        }

    }
    const handleImageChangePlus = () => {
        if (imageIndex === selectedPostMedia.length - 1) {
            console.log('limit reached', imageIndex)
            return
        } else {
            setImageIndex(imageIndex + 1)
            console.log('nextImage', imageIndex)
        }

    }
    const handleCloseButton = () => {
        setSelectedPostMedia([])
        setImageIndex(0)
    }
    const handleDateTimePicker = (newDateTime) => {
        console.log('dateTiemStart', newDateTime)
        setDateTime(newDateTime)


    }
    const handleEmojiButton = () => {
        let emojiAction = emojiButton
        setEmojiButton(!emojiAction)
    }
    const handleMobilePostView = ()=>{
        let postView = mobilePostView
        setMobilePostView(!postView)
    }

    return (
        <div className='max-w-screen mt-0 px-4 lg:px-12 '>
            <div className='flex flex-col lg:flex-row'>
           
            
        <div className='flex flex-col justify-center  '>
        <div className='flex items-center gap-2'>
      <h3 className='p-4 text-xl font-bold text-gray-800'>Create Post</h3>
      <div className='w-3/5 bg-violet-200 h-[1px]'></div>
      </div>
          <div className='flex sm:flex-row flex-wrap gap-3 '>
            <div className='flex-col w-fit'>
              <div className={`flex flex-row-reverse ${screenWidth > 450 ? 'hidden' : 'visible'}`}>
                {/* <button onClick={handleMobilePostView} className='flex flex-row'>
                  <p>Post View</p>
                  {mobilePostView ? <CloseIcon /> : <PreviewIcon />}
                </button> */}
              </div>
              <div className='flex flex-wrap gap-4 '>
                <div className='flex flex-col bg-gray-100 rounded p-4  gap-4 max-w-fit '>
                  <div className='flex flex-col gap-2 max-w-fit'>
                    <p className='mb-2 text-gray-700 font-bold ml-3'>Select channels</p>
                    <p className='text-gray-700 font-semibold text-sm ml-3'>Select social media accounts</p>
                  </div>
                  <ChannelSelect
                    initialSelectedChannel={selectedChannels}
                    onChannelSelect={handleChannelSelect}
                    screenWidth={screenWidth}
                  />
                </div>
                {/* {mobilePostView && (
                  <div className='z-50'>
                    <FullpostView
                      selectedChannels={selectedChannels}
                      selectedPostMedia={selectedPostMedia}
                      selectedPostContent={selectedPostContent}
                      selectedFiles1={selectedFiles1}
                      imageIndex={imageIndex}
                    />
                  </div>
                )}
               */}
               
                  <div className='flex flex-col bg-gray-100 w-fit rounded p-4 gap-2'>
                    <p className='mb-2 text-gray-700 font-bold ml-3'>Add media</p>
                    <p className='text-gray-700 text-sm mb-2  m'>Share photos or a video. Instagram posts can't exceed 10 photos.</p>
                    <div>
                      <label htmlFor="fileInput" className="cursor-pointer">
                        <div className='flex flex-row gap-1'>
                          <AddCircleIcon />
                          <p className='text-slate-500'>Add media files</p>
                        </div>
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                        accept=".png, .jpg, .jpeg, .mp4"
                        multiple
                      />
                    </div>
                    {selectedPostMedia.length > 0 && (
                      <div className="flex flex-wrap gap-4">
                        {selectedPostMedia.map((media, index) => (
                          <div key={index} className="relative">
                            <CloseButton
                              onClick={() => handleCutMedia(index)}
                              className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-md"
                            />
                            <div onClick={() => handleMediaClick(index)} className={index === currentMediaIndex ? "border border-blue-500 rounded-lg" : "rounded-lg"}>
                              {media.fileType.startsWith('image/') && (
                                <img src={`data:image/png;base64,${media.url}`} alt="Preview" className="w-20 h-20" />
                              )}
                              {media.fileType.startsWith('video/') && (
                                <video className="w-20 h-20">
                                  <source src={`data:video/mp4;base64,${media.url}`} type={media.type} />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {selectedPostMedia.length > 0 && (
                      <div className="m-6">
                        {selectedPostMedia[currentMediaIndex].fileType.startsWith('image/') && (
            <div
            className=''
            style={{
              backgroundImage: `url('data:image/png;base64,${selectedPostMedia[currentMediaIndex].url}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: '350px',  // Smaller width for compact posts
              height: '350px', // Adjusted height for square posts, but it could handle most sizes
              maxWidth: '100%',  // Ensures it doesn't overflow on smaller screens
              maxHeight: '100%',
              borderRadius: '8px',  // Optional: adds a subtle rounded corner for aesthetic
              overflow: 'hidden',   // Makes sure content stays within the div
            }}
          ></div>
          
              

                        //  <img src={`data:image/png;base64,${selectedPostMedia[currentMediaIndex].url}`} alt="Preview" className="mt-4 max-w-80 h-80" />
                        )}
                        {selectedPostMedia[currentMediaIndex].fileType.startsWith('video/') && (
                          <video controls className="max-w-80 h-80 mt-4">
                            <source src={`data:video/mp4;base64,${selectedPostMedia[currentMediaIndex].url}`} type={selectedFiles1[currentMediaIndex].type} />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    )}
                  </div>
               
                  <div className='flex flex-col gap-2 bg-gray-100 w-fit rounded p-3'>
                    <div className='flex flex-col gap-2'>
                      <p className='mb-2 text-gray-600 font-bold ml-3'>Post details</p>
                      <p className='text-gray-600 text-sm mb-2 ml-3'>Caption</p>
                    </div>
                    <textarea
                      rows="4"
                      value={selectedPostContent}
                      placeholder='Whats on your mind?'
                      onChange={handleTextArea}
                      className='p-4 rounded-2xl w-fit'
                    />
                    {/* <EmojiEmotionsIcon className='p-2 cursor-pointer w-full' onClick={handleEmojiButton} /> */}
                    {/* {emojiButton && <EmojiPicker />} */}
                    <AiSide />
                  </div>
              </div>
           
            </div>
          </div>
          <div className=' flex flex-col lg:flex-row gap-8 justify-end items-center  mt-12   p-8 border-2 border-violet-200 hover:bg-violet-200 rounded-md'>
      
      <div className=''>
        <BlurBarrier />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker onChange={handleDateTimePicker} value={dateTime} label="Schedule" />
          </DemoContainer>
        </LocalizationProvider>
      </div>
  
    <button
      className='cta'
      disabled={selectedPostMedia.length === 0 && selectedPostContent === ''}
      onClick={handleSharePost}
    >
      {isLoading ? 'Loading…' : 'POST'}
    </button>
  </div>
          <div className='flex items-center gap-2 my-12'>
      <h3 className='p-4 text-xl font-bold text-gray-800'>Post View</h3>
      <div className='w-3/5 bg-gray-400 h-[1px]'></div>
      </div>
        
            <FullpostView
              selectedChannels={selectedChannels}
              selectedPostMedia={selectedPostMedia}
              selectedPostContent={selectedPostContent}
              selectedFiles1={selectedFiles1}
              imageIndex={imageIndex}
            />
     
        </div>
        {/* <div className='w-2 h-full bg-gray-500'></div> */}
        

            
        </div>
        <Notification apiResponse={apiResponse} setApiResponse={setApiResponse} />
      </div>
      
    );
}
