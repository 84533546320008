// src/components/LinkedInPost.js

import React from 'react';

const LinkedInPost = ({  userName, content, selectedPostMedia,imageIndex,selectedFiles1 }) => {
  return (
    <div className="border border-gray-300 rounded-lg p-4 max-w-96 mx-auto bg-white mb-20 shadow-lg">
      <header className="flex items-center mb-4">
        <img src='/img/logoIcon.png' alt="User" className="rounded-full w-12 h-12 mr-4" />
        <div>
          <h2 className="text-lg font-semibold">{userName}</h2>
       
        </div>
      </header>
      <div className="mb-4">
        {content}
      </div>
      {selectedPostMedia.length > 0 &&
              <div className="">
                {selectedPostMedia[imageIndex].fileType.startsWith('image/') && (
                  <img src={`data:image/png;base64,${selectedPostMedia[imageIndex].url}`} alt="Preview" className="w-72 h-80" />
                )}
                {selectedPostMedia[imageIndex].fileType.startsWith('video/') && (
                  <video controls className="w-72 h-80">
                    <source src={`data:video/mp4;base64,${selectedPostMedia[imageIndex].url}`} type={selectedFiles1[imageIndex].type} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>}
      <div className="flex justify-between items-center text-gray-600 mt-4">
        <button className="flex items-center">
          <span className="mr-2">👍</span> Like
        </button>
        <button className="flex items-center">
          <span className="mr-2">💬</span> Comment
        </button>
        <button className="flex items-center">
          <span className="mr-2">🔄</span> Repost
        </button>
        <button className="flex items-center">
          <span className="mr-2">✉️</span> Send
        </button>
      </div>
    </div>
  );
};

export default LinkedInPost;
