import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios"
import {useDispatch} from "react-redux"
import { login } from "../store/slices/authSlice";
import  { useNavigate } from 'react-router-dom'
import Loading from "../components/loader";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { Helmet } from "react-helmet-async";


export default function Login() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [redirectToHome, setRedirectToHome] = useState(false);  
  const [isLoading, setIsLoading] = useState(false)
  const [loginError,setLoginError] =useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogin = async (e) => {
    setIsLoading(true)
    e.preventDefault();

    try {
      // Replace 'https:digisolvertechnologies.in/register' with your actual registration API endpoint   https://digisolvertechnologies.in
      const response = await axios.post('https://digisolvertechnologies.in/users/login', formData);

      // Check if the registration was successful
      if (response.status === 201) {
        setIsLoading(false)
        console.log('login successful!',response);
        dispatch(login(response.data.user.name))
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("userName", response.data.user.name);
        setRedirectToHome(true)
        
        // You may want to redirect the user or perform other actions after successful registration
      } else if(response.status===400){
        setIsLoading(false)
        setLoginError(response.data)
        console.log('login failed:', response.data);
      }
    } catch (error) {
      setIsLoading(false)
      setLoginError(error.request.response)
      console.error('An error occurred during login:', error.request.response);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  if (redirectToHome) {

    navigate("/user/socialAccounts");
  }
    return (
      <div className="bgHero h-screen">
         <Helmet>
        <title>Login - SocialSyncHub</title>
        <meta
          name="description"
          content="Log in to your SocialSyncHub account to manage multiple social media platforms efficiently."
        />
        <meta
          name="keywords"
          content="login, social media management, SocialSyncHub, social media tool"
        />
        <link rel="canonical" href="https://socialsynchub.com/signin" />
      </Helmet>
      <Header/>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
        <div className="w-full flex justify-center py-20 bg-white h-full">

       
        <div className="flex  flex-col items-center justify-center max-w-2xl shadow-lg  px-4 py-12  rounded-md">
          <div className="flex flex-col justify-center items-center">
            <img
              className="mx-auto h-10 w-auto"
              src="/img/logoIcon.png"
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-700">
              Sign in to your account
            </h2>
            <p className="text-red-600">{loginError}</p>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={(e)=>handleLogin(e)} onChange={(e)=>handleChange(e)} method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="#" className="font-semibold text-orange-600 hover:text-orange-500">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  {!isLoading ? "Sign in" :<Loading/>}
               
                  
                </button>
              </div>
            </form>
  
            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{' '}
              <Link to="/registerUser" className="font-semibold leading-6 text-gray-600 hover:text-gray-500">
              Create a new accont
              </Link>
            </p>
          </div>
        </div>
        </div>
        <Footer/>
      </div>
    )
  }
  