import Skeleton from '@mui/material/Skeleton';

function PostCard() {
  return (
    <div className='flex flex-col items-start gap-4 text-red-500'>
<p>*Select a channel to see post view.</p>
<div className=''>
      <div className='flex flex-row'>
      <Skeleton variant="circular" width={40} height={40} animation="wave" />
      <div className='flex flex-col'>
      <Skeleton variant="text" width="30%" height={10} animation="wave" className='w-28'/>
      <Skeleton variant="text" width="30%" height={10} animation="wave" className='w-28'/>
      </div>
      
      </div>
      
      <Skeleton variant="text" width="30%" height={10} animation="wave" className='w-28' />
      <Skeleton variant="text" width="40%" height={10} animation="wave" className='w-28'/>
      <Skeleton variant="rectangular" height={190} animation="wave" className='w-56'/>
      
    </div>
    </div>
   
  );
}

export default PostCard;