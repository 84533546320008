import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import PostCard from '../../PostCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';

export default function XPostView({ selectedPostMedia, selectedPostContent, selectedFiles1, imageIndex }) {
  const socialMediaString = sessionStorage.getItem('socialMedia');
  let socialMedia = [];
  if (socialMediaString) {
    socialMedia = JSON.parse(socialMediaString);
  }

  useEffect(() => {
    const fetchData = async () => {
      const xCredentials = socialMedia.find(social => social.platform === 'X');
      if (!xCredentials) {
        console.error("No credentials found for platform 'X'");
        return;
      }

      try {
        const response = await axios.get(
          'http://localhost:5000/X/me',
          {
            params: { // Use params for passing query parameters
              accessTokenX: xCredentials.accessToken,
              accessTokenSecretX: xCredentials.accessTokenSecret,
            },
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` // Assuming you store the token in localStorage
            }
          }
        );

        console.log('User fetched:', response.data);
        // Handle response data as needed
      } catch (error) {
        console.error('Error getting user data:', error);
        // Handle errors appropriately
      }
    };

    // fetchData();

  }, [socialMedia,selectedPostContent]); // Run effect when socialMedia changes

  return (
    <div className='border border-gray-300 rounded-lg p-4 max-w-xl mx-auto bg-white my-4 shadow-lg'>
      <div className='flex flex-col'>
        <div className='flex flex-row gap-1 w-80'>
          <img className='rounded-full w-8 h-8' src='/img/logoIcon.png' alt='Logo' />
          <div className='flex flex-col w-60 gap-1 justify-start'>
            <div className='flex flex-row w-full justify-between'>
              <p>@ojhaabhishekraj</p>
              <MoreHorizIcon />
            </div>
            {selectedPostContent && <p>{selectedPostContent}</p>}
            {selectedPostMedia.length > 0 &&
              <div className="">
                {selectedPostMedia[imageIndex].fileType.startsWith('image/') && (
                  <img src={`data:image/png;base64,${selectedPostMedia[imageIndex].url}`} alt="Preview" className="w-72 h-80" />
                )}
                {selectedPostMedia[imageIndex].fileType.startsWith('video/') && (
                  <video controls className="w-72 h-80">
                    <source src={`data:video/mp4;base64,${selectedPostMedia[imageIndex].url}`} type={selectedFiles1[imageIndex].type} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>}
          </div>
        </div>
        <div className='flex flex-row justify-evenly p-2'>
          <img src='/img/postViewIcon/X/heart.png' className='w-4' alt='Heart Icon' />
          <img src='/img/postViewIcon/X/retweet.png' className='w-4' alt='Retweet Icon' />
          <img src='/img/postViewIcon/X/chat.png' className='w-4' alt='Chat Icon' />
        </div>
      </div>
    </div>
  );
}
